import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  BlockLink,
  BorderListItem,
  ListBookCover,
  ListTitle,
  Tag
} from '../../../../../assets/stylesheets/StyledComponents'
import { Footer } from '../../../../organisms/Footer'
import { PropsType } from './Container'
import classNames from 'classnames'
import { Hr } from '../../../../atoms/Hr'

const Presenter: React.FC<PropsType> = ({ books }) => {
  const [viewType, setViewType] = useState('grid')
  const handleClickListType = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setViewType('list')
  }
  const handleClickGridType = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setViewType('grid')
  }
  const listTypeClassName = classNames('button', 'is-small', {
    'is-light': viewType === 'list',
    'is-selected': viewType === 'list'
  })
  const gridTypeClassName = classNames('button', 'is-small', {
    'is-light': viewType === 'grid',
    'is-selected': viewType === 'grid'
  })
  return (
    <>
      <section className="section has-background-primary">
        <div className="container">
          <div className="field has-addons has-addons-centered">
            <p className="control is-expanded">
              <input
                className="input"
                type="text"
                placeholder="本のタイトルを入力してください"
              />
            </p>
            <p className="control">
              <button className="button is-success">検索</button>
            </p>
          </div>
        </div>
      </section>
      <div
        className="section"
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
      >
        <div className="container has-text-centered">
          <div className="level is-mobile">
            <div className="level-left">
              <div className="level-item">
                <small>{books.length}件</small>
              </div>
            </div>
            {books.length > 0 && (
              <div className="level-right">
                <div className="level-item">
                  <div className="buttons has-addons">
                    <button
                      className={listTypeClassName}
                      onClick={handleClickListType}
                    >
                      <span className="icon">
                        <i className="material-icons">view_list</i>
                      </span>
                    </button>
                    <button
                      className={gridTypeClassName}
                      onClick={handleClickGridType}
                    >
                      <span className="icon">
                        <i className="material-icons">view_module</i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Hr />
      {books.length > 0 ? (
        <>
          {viewType === 'grid' && (
            <section className="section" style={{ paddingTop: '0' }}>
              <div className="container">
                <BookCoverColumns className="columns is-multiline is-mobile">
                  {books.map(book => (
                    <div
                      className="column is-6-mobile is-3-tablet"
                      key={book.id}
                    >
                      <Link to={`/books/${book.id}`}>
                        {book.supportCoachId ? (
                          <Tag className="tag is-warning is-rounded">
                            著者本人サポート
                          </Tag>
                        ) : (
                          <Tag className="tag is-white is-rounded" />
                        )}
                        <GridBookCover>
                          <figure className="image">
                            <img src={book.coverUrl} alt="cover" />
                          </figure>
                        </GridBookCover>
                        <BookTitle>{book.title}</BookTitle>
                      </Link>
                    </div>
                  ))}
                </BookCoverColumns>
                <div className="has-text-centered">
                  <button className="button is-rounded is-dark is-outlined">
                    <span className="icon">
                      <i className="material-icons">keyboard_arrow_down</i>
                    </span>
                    <span>もっと見る</span>
                  </button>
                </div>
              </div>
            </section>
          )}
          {viewType === 'list' && (
            <section className="section" style={{ paddingTop: '0' }}>
              <div className="container">
                <ul className="list is-shadowless is-radiusless">
                  {books.map(book => (
                    <BorderListItem key={book.id}>
                      <Link to={`/books/${book.id}`}>
                        <div className="media">
                          <ListBookCover className="media-left">
                            <figure className="image">
                              <img src={book.coverUrl} alt="Placeholder" />
                            </figure>
                          </ListBookCover>
                          <div className="media-content">
                            {book.supportCoachId && (
                              <Tag className="tag is-warning is-rounded">
                                著者本人サポート
                              </Tag>
                            )}
                            <div className="content has-text-black">
                              <ListTitle>{book.title}</ListTitle>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </BorderListItem>
                  ))}
                </ul>
              </div>
            </section>
          )}
        </>
      ) : (
        <>
          <section className="section">
            <div className="container">
              <h1 className="has-text-centered">
                検索したワードと紐づく
                <br />
                タイトルの本が見つかりませんでした。
              </h1>
            </div>
          </section>
          <Hr />
          <section className="section">
            <div className="container">
              <h1 className="has-text-centered">
                別のキーワードもしくは
                <br />
                カテゴリから本を検索してください。
              </h1>
            </div>
          </section>
          <section className="section">
            <div className="container">
              <div className="has-text-centered">
                <BlockLink
                  to="/categories"
                  className="button is-rounded is-primary is-outlined has-text-weight-bold"
                >
                  カテゴリから本を探す＞
                </BlockLink>
              </div>
            </div>
          </section>
        </>
      )}
      <Footer />
    </>
  )
}

const BookCoverColumns = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`

const GridBookCover = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 4px;
`

const BookTitle = styled.div`
  font-size: 0.8rem;
`

export default Presenter
