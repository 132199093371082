import Redux from 'redux'
import { ModuleActions } from './index'

export type State = {
  [key: string]: Error | undefined
}

const initialState: State = {}

export enum ActionTypes {
  REMOVE_ERROR = 'REMOVE_ERROR'
}

export const reducer: Redux.Reducer<State, ModuleActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.REMOVE_ERROR: {
      const name = action.payload.name.replace('_REQUEST', '')
      return { ...state, [name]: undefined }
    }
    default:
      const matches = /(.*)_(REQUEST|FAILURE)/.exec(action.type)
      if (!matches) {
        return { ...state }
      }

      const [, requestName] = matches

      if (action.error && action.payload instanceof Error) {
        return {
          ...state,
          [requestName]: action.payload
        }
      }

      return {
        ...state,
        [requestName]: undefined
      }
  }
}

export const removeError = (name: string) => ({
  type: ActionTypes.REMOVE_ERROR as ActionTypes.REMOVE_ERROR,
  payload: { name },
  meta: {},
  error: false
})

export type Actions = ReturnType<typeof removeError>

export const createErrorSelector = (
  state: State,
  actionType: string
): Error | undefined => {
  const type = actionType.replace('_REQUEST', '')
  if (type in state) {
    return state[type]
  }
  return undefined
}
