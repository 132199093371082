import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../modules'
import { createLoadingSelector } from '../../../modules/Loading'
import { ActionTypes, fetchBookRequest } from '../../../modules/Book'
import { createErrorSelector } from '../../../modules/Error'

export const mapStateToProps = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const books = useSelector(
    (state: ApplicationState) => state.book.popularBooks
  )
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isLoading = useSelector((state: ApplicationState) =>
    createLoadingSelector(state.loading, ActionTypes.FETCH_POPULAR_BOOK_REQUEST)
  )
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const error = useSelector((state: ApplicationState) =>
    createErrorSelector(state.error, ActionTypes.FETCH_POPULAR_BOOK_REQUEST)
  )
  return { books, isLoading, error }
}

export const mapDispatchToProps = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => {
    return bindActionCreators({ fetchBookRequest }, dispatch)
  }, [dispatch])
}

export type PropsType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>
