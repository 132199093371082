import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { PropsType } from './Container'

const Presenter: React.FC<PropsType> = ({
  children,
  isOpenDrawer,
  isOpenNotification,
  systemHideDrawer,
  systemHideNotification
}) => {
  const onClickPusher = () => {
    if (isOpenDrawer) {
      systemHideDrawer()
    }
    if (isOpenNotification) {
      systemHideNotification()
    }
  }

  const drawerClass = classNames({
    drawerShown: isOpenDrawer,
    notificationShown: isOpenNotification
  })

  return (
    <Pusher className={drawerClass} onClick={onClickPusher}>
      {children}
    </Pusher>
  )
}

const Pusher = styled.div`
  background: #ffffff;
  overflow: hidden;
  z-index: 2;
  position: relative;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &:after {
    position: fixed;
    top: 0;
    right: 0;
    content: '';
    background-color: rgba(0, 0, 0, 0.4);
    overflow: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s;
    will-change: opacity;
    z-index: 1000;
  }
  &.drawerShown {
    transform: translate3d(260px, 0, 0);
    &:after {
      width: 100%;
      height: 100%;
      opacity: 1;
    }
  }
  &.notificationShown {
    transform: translate3d(-320px, 0, 0);
    &:after {
      width: 100%;
      height: 100%;
      opacity: 1;
    }
  }
`

export default Presenter
