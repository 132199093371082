import React from 'react'
import styled from 'styled-components'

export const Footer: React.FC<{}> = () => {
  return (
    <StyledFooter className="footer has-background-grey-light">
      <div className="content">
        <p className="has-text-grey">©honq.com</p>
      </div>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  margin-top: auto;
`
