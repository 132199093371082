import React from 'react'
import { Link } from 'react-router-dom'
import BookCoverIcon from '../../../assets/images/book_cover_icon.png'
import styled from 'styled-components'
import { ListTitle } from '../../../assets/stylesheets/StyledComponents'
import { Footer } from '../../organisms/Footer'

export const CoachTopPage: React.FC<{}> = () => {
  return (
    <>
      <div className="section">
        <div className="container has-text-centered">
          <h1 className="title is-size-5">HOME</h1>
          <UnderLessLink to="/coach/chats">
            <article className="message is-warning">
              <div className="message-body">
                ！ 新着・未読の質問チャットあり
              </div>
            </article>
          </UnderLessLink>
        </div>
      </div>
      <CardSection
        className="section has-background-light"
        style={{ paddingTop: '20px' }}
      >
        <div className="container">
          <div className="card">
            <div className="card-content">
              <div className="level is-mobile">
                <div className="level-left">
                  <div className="level-item">
                    <h2 className="title is-4">本おすすめ記事</h2>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <p className="has-text-grey">(225)</p>
                  </div>
                </div>
              </div>
              <div className="level is-mobile">
                <div className="level-item">
                  <Link
                    to="/coach/articles"
                    className="button is-dark is-outlined is-rounded"
                  >
                    記事一覧
                  </Link>
                </div>
                <div className="level-item">
                  <Link
                    to="/coach/articles/create"
                    className="button is-info is-rounded"
                  >
                    投稿する
                  </Link>
                </div>
              </div>
              <div>
                <div className="has-text-grey">下書き(2)</div>
                <ul className="list is-shadowless">
                  <DraftListItem>
                    <Link
                      to="/coach/articles/1"
                      className="has-text-black"
                      style={{ padding: '8px 16px', display: 'block' }}
                    >
                      下書きタイトルタイトル
                    </Link>
                  </DraftListItem>
                  <DraftListItem>
                    <Link
                      to="/coach/articles/1"
                      className="has-text-black"
                      style={{ padding: '8px 16px', display: 'block' }}
                    >
                      下書きタイトルタイトル
                    </Link>
                  </DraftListItem>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </CardSection>
      <CardSection className="section has-background-light">
        <div className="container">
          <div className="card">
            <div className="card-content">
              <div className="level is-mobile">
                <div className="level-left">
                  <div className="level-item">
                    <h2 className="title is-4">本の解説とQA</h2>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <p className="has-text-grey">
                      <Link to="/coach/request_book" className="button is-text">
                        ＋本を追加
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <ul className="list is-paddingless is-shadowless">
                {[...Array(3)].map((_, i) => (
                  <BorderListItem key={i}>
                    <div className="media">
                      <ListBookCover className="media-left">
                        <figure className="image">
                          <img src={BookCoverIcon} alt="Placeholder" />
                        </figure>
                      </ListBookCover>
                      <div className="media-content">
                        <div className="content">
                          <ListTitle>
                            初速思考ああああああああああああああああ
                          </ListTitle>
                        </div>
                      </div>
                    </div>
                    <div className="level is-mobile">
                      <div className="level-item">
                        <Link
                          to="/coach/qa"
                          className="button is-dark is-outlined is-rounded"
                        >
                          一覧(75)
                        </Link>
                      </div>
                      <div className="level-item">
                        <Link
                          to="/coach/qa/create"
                          className="button is-info is-rounded"
                        >
                          投稿する
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="has-text-grey">下書き(2)</div>
                      <ul className="list is-shadowless">
                        <DraftListItem>
                          <Link
                            to="/coach/qa/1"
                            className="has-text-black"
                            style={{ padding: '8px 16px', display: 'block' }}
                          >
                            下書きタイトルタイトル
                          </Link>
                        </DraftListItem>
                        <DraftListItem>
                          <Link
                            to="/coach/qa/1"
                            className="has-text-black"
                            style={{ padding: '8px 16px', display: 'block' }}
                          >
                            下書きタイトルタイトル
                          </Link>
                        </DraftListItem>
                      </ul>
                    </div>
                  </BorderListItem>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </CardSection>
      <CardSection className="section has-background-light">
        <div className="container">
          <div className="card">
            <div className="card-content">
              <div className="level is-mobile">
                <div className="level-left">
                  <div className="level-item">
                    <h2 className="title is-4">質問チャット</h2>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <p className="has-text-grey">
                      <Link to="/coach/chats" className="button is-text">
                        一覧(225)
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="level is-mobile" style={{ marginBottom: '4px' }}>
                <div className="level-left">
                  <div className="level-item">
                    <div className="subtitle">設定中のプラン</div>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <Link to="/coach/plans/create" className="button is-text">
                      ＋プランを追加
                    </Link>
                  </div>
                </div>
              </div>
              <ul className="list is-paddingless is-shadowless">
                {[...Array(3)].map((_, i) => (
                  <BorderListItem key={i}>
                    <div className="media">
                      <ListBookCover className="media-left">
                        <figure className="image">
                          <img src={BookCoverIcon} alt="Placeholder" />
                        </figure>
                      </ListBookCover>
                      <div className="media-content">
                        <div className="content">
                          <ListTitle>
                            初速思考ああああああああああああああああ
                          </ListTitle>
                          <p>料金：1000円</p>
                          <p>
                            {i === 1 ? (
                              <span className="tag is-light">質問停止中</span>
                            ) : (
                              <span className="tag is-success">質問受付中</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <Link
                      to="/coach/plans/1"
                      className="button is-dark is-outlined is-rounded is-fullwidth"
                    >
                      プランを変更する
                    </Link>
                  </BorderListItem>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </CardSection>
      {/*<CardSection className="section has-background-light">*/}
      {/*  <div className="container">*/}
      {/*    <div className="card">*/}
      {/*      <div className="card-content">*/}
      {/*        <div className="level is-mobile">*/}
      {/*          <div className="level-left">*/}
      {/*            <div className="level-item">*/}
      {/*              <h2 className="title is-4">コース情報</h2>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="level-right">*/}
      {/*            <div className="level-item">*/}
      {/*              <p className="has-text-grey">*/}
      {/*                <Link to="/courses/1" className="button is-text">*/}
      {/*                  紹介ページ確認*/}
      {/*                </Link>*/}
      {/*              </p>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*        <div className="buttons">*/}
      {/*          <Link*/}
      {/*            to="/coach/edit/info"*/}
      {/*            className="button is-dark is-outlined is-rounded is-fullwidth"*/}
      {/*          >*/}
      {/*            コース基本情報　編集*/}
      {/*          </Link>*/}
      {/*          <Link*/}
      {/*            to="/coach/edit/summary"*/}
      {/*            className="button is-dark is-outlined is-rounded is-fullwidth"*/}
      {/*          >*/}
      {/*            コース概要・紹介文編集*/}
      {/*          </Link>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</CardSection>*/}
      <CardSection className="section has-background-light">
        <div className="container">
          <div className="card">
            <div className="card-content">
              <div className="level is-mobile">
                <div className="level-left">
                  <div className="level-item">
                    <h2 className="title is-4">コーチ設定</h2>
                  </div>
                </div>
              </div>
              <div className="buttons">
                <Link
                  to="/coach"
                  className="button is-dark is-outlined is-rounded is-fullwidth"
                >
                  プロフィール編集
                </Link>
              </div>
            </div>
          </div>
        </div>
      </CardSection>
      <CardSection
        className="section has-background-light"
        style={{ paddingBottom: '20px' }}
      >
        <div className="container">
          <div className="card">
            <div className="card-content">
              <div className="level is-mobile">
                <div className="level-left">
                  <div className="level-item">
                    <h2 className="title is-4">売上管理</h2>
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="level is-mobile">
                  <div className="level-left">
                    <div className="level-item">
                      <p>売上残高</p>
                    </div>
                  </div>
                  <div className="level-right">
                    <div className="level-item">
                      <p className="is-size-3">¥30,300</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="buttons">
                <Link
                  to="/"
                  className="button is-dark is-outlined is-rounded is-fullwidth"
                >
                  出金申請
                </Link>
                <Link
                  to="/"
                  className="button is-dark is-outlined is-rounded is-fullwidth"
                >
                  売上履歴
                </Link>
              </div>
            </div>
          </div>
        </div>
      </CardSection>
      <Footer />
    </>
  )
}

const UnderLessLink = styled(Link)`
  &&& {
    text-decoration: none;
  }
`

const BorderListItem = styled.li`
  padding: 16px 0;
  & + & {
    border-top: 1px solid hsla(19, 5%, 89%, 1);
  }
`

const ListBookCover = styled.div`
  width: 40%;
`

const CardSection = styled.section`
  &&& {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

const DraftListItem = styled.li`
  background: hsla(19, 5%, 89%, 1);
  border-radius: 8px;
  margin-top: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`
