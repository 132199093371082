import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { BlockButton } from '../../../../assets/stylesheets/StyledComponents'
import { Footer } from '../../../organisms/Footer'

export const CoachChatCreatePage: React.FC<{}> = () => {
  const [coverUrl, setCoverUrl] = useState('')
  const handleChangeCover = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files === null || files.length === 0) {
      return
    }
    const file = files[0]
    // setCoverFile(file)
    setCoverUrl(URL.createObjectURL(file))
  }

  return (
    <>
      <section
        className="section has-background-grey-light"
        style={{ paddingTop: '8px', paddingBottom: '8px' }}
      >
        <div className="container">
          <div className="has-text-centered">メッセージ投稿</div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <p className="has-text-weight-bold" style={{ marginBottom: '8px' }}>
            直前のメッセージ
          </p>
          <article className="media">
            <figure className="media-left">
              <p className="image is-64x64">
                <img
                  src="https://bulma.io/images/placeholders/128x128.png"
                  alt="icon"
                />
              </p>
            </figure>
            <div className="media-content">
              <div className="content">
                <p style={{ marginBottom: '4px' }}>
                  <small className="has-text-weight-bold">
                    ユーザー名あああ
                  </small>
                  <small style={{ paddingLeft: '4px' }}>2019/12/01 11:00</small>
                </p>
                <p>
                  テキストああああああああああああああああああああああああああああああああああああああああああ
                </p>
              </div>
            </div>
          </article>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <form>
            <div className="field">
              <label className="label">投稿内容</label>
              <div className="control">
                <textarea className="textarea has-fixed-size" />
              </div>
              <div className="has-text-right has-text-grey">
                <small>
                  {0}/{100}文字
                </small>
              </div>
            </div>
            <div className="field">
              <label className="label">画像添付</label>
              <div className="control">
                {coverUrl !== '' && (
                  <HiddenOverflowFigure className="image is-16by9">
                    <img src={coverUrl} alt="cover" />
                  </HiddenOverflowFigure>
                )}
                <div className="file is-inline-flex">
                  <label className="file-label">
                    <input
                      className="file-input"
                      type="file"
                      onChange={handleChangeCover}
                    />
                    {coverUrl === '' ? (
                      <span className="file-cta">
                        <span className="file-label">＋追加する</span>
                      </span>
                    ) : (
                      <span className="button is-text">変更する</span>
                    )}
                  </label>
                </div>
              </div>
            </div>
            <div className="has-text-centered">
              <StyledBlockButton
                className="button is-rounded is-info is-medium"
                style={{ marginTop: '2rem', marginBottom: '1rem' }}
              >
                メッセージを投稿する
              </StyledBlockButton>
              <Link
                to="/coach/chats/1"
                className="button is-rounded is-primary is-outlined"
              >
                キャンセル
              </Link>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </>
  )
}

const StyledBlockButton = styled(BlockButton)`
  &.button {
    min-height: auto;
  }
`

const HiddenOverflowFigure = styled.figure`
  overflow: hidden;
`
