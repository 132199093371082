import Redux from 'redux'
import { ModuleActions } from './index'

export type State = {
  [key: string]: boolean
}

const initialState: State = {}

export const reducer: Redux.Reducer<State, ModuleActions> = (
  state = initialState,
  action
) => {
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(action.type)
  if (!matches) {
    return { ...state }
  }

  const [, requestName, requestState] = matches
  return {
    ...state,
    [requestName]: requestState === 'REQUEST'
  }
}

export const createLoadingSelector = (
  state: State,
  actionType: string
): boolean => {
  const type = actionType.replace('_REQUEST', '')
  if (type in state) {
    return state[type]
  }
  return false
}
