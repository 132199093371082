import { put, call, select, takeLatest } from 'redux-saga/effects'
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router'
import {
  ActionTypes,
  systemHideDrawer,
  systemHideNotification,
  systemLoadPageRequest,
  systemLoadPageAction,
  systemLoadPageSuccess,
  systemLoadPageFailure
} from '../modules/Application'
import {
  fetchCategorySuccess,
  fetchPopularCategoryRequest,
  findCategorySuccess
} from '../modules/Category'
import { fetchCategoryApi, findCategoryApi } from '../apis/CategoryApi'
import { fetchBookApi, findBookApi } from '../apis/BookApi'
import {
  fetchBookSuccess,
  fetchPopularBookRequest,
  findBookSuccess
} from '../modules/Book'
import { fetchArticleApi } from '../apis/ArticleApi'
import {
  fetchArticleSuccess,
  fetchLatestArticleRequest,
  fetchPopularArticleRequest
} from '../modules/Article'
import { fetchQaApi } from '../apis/QaApi'
import { fetchQaSuccess } from '../modules/Qa'
import { fetchCoachApi } from '../apis/CoachApi'
import { fetchCoachSuccess } from '../modules/Coach'

function* handleLocationChange(action: LocationChangeAction) {
  const isOpenDrawer = yield select(state => state.application.isOpenDrawer)
  if (isOpenDrawer) {
    yield put(systemHideDrawer())
  }
  const isOpenNotification = yield select(
    state => state.application.isOpenNotification
  )
  if (isOpenNotification) {
    yield put(systemHideNotification())
  }
  // TODO: URLが変更されているかチェックする機構を入れたい
  if (
    action.payload.location.pathname !== '/login' &&
    action.payload.location.pathname !== '/signup'
  ) {
    yield put(systemLoadPageRequest())
  }
}

function* handleLoadPage(action: ReturnType<typeof systemLoadPageAction>) {
  try {
    switch (action.payload.type) {
      case 'USER_TOP': {
        yield put(fetchPopularBookRequest())
        yield put(fetchPopularCategoryRequest())
        yield put(fetchLatestArticleRequest())
        yield put(fetchPopularArticleRequest())
        break
      }
      case 'USER_CATEGORY_INDEX': {
        const categories = yield call(fetchCategoryApi)
        yield put(fetchCategorySuccess(categories))
        break
      }
      case 'USER_CATEGORY_SHOW': {
        const [id] = action.payload.props
        const category = yield call(findCategoryApi, id)
        yield put(findCategorySuccess(category))
        const books = yield call(fetchBookApi, category.id, 6)
        yield put(fetchBookSuccess(books))
        const articles = yield call(fetchArticleApi, category.id, 6)
        yield put(fetchArticleSuccess(articles))
        break
      }
      case 'USER_BOOK_INDEX': {
        const [id] = action.payload.props
        const category = yield call(findCategoryApi, id)
        yield put(findCategorySuccess(category))
        const books = yield call(fetchBookApi, category.id)
        yield put(fetchBookSuccess(books))
        const articles = yield call(fetchArticleApi, category.id)
        yield put(fetchArticleSuccess(articles))
        break
      }
      case 'USER_BOOK_SHOW': {
        const [id] = action.payload.props
        const book = yield call(findBookApi, id)
        yield put(findBookSuccess(book))
        const qaList = yield call(fetchQaApi, book.category.ref)
        yield put(fetchQaSuccess(qaList))
        break
      }
      case 'USER_SEARCH_INDEX':
      case 'USER_ARTICLE_INDEX':
      case 'USER_ARTICLE_SHOW':
      case 'USER_QA_SHOW': {
        break
      }
      case 'USER_COACH_INDEX': {
        const [id] = action.payload.props
        const book = yield call(findBookApi, id)
        yield put(findBookSuccess(book))
        const coaches = yield call(fetchCoachApi, book.id)
        yield put(fetchCoachSuccess(coaches))
        break
      }
      case 'OTHER': {
        break
      }
    }
    yield put(systemLoadPageSuccess())
  } catch (e) {
    yield put(systemLoadPageFailure(new Error(e.code)))
  }
}

export function* watchApplicationAction() {
  yield takeLatest(LOCATION_CHANGE, handleLocationChange)
  yield takeLatest(ActionTypes.SYSTEM_LOAD_PAGE_ACTION, handleLoadPage)
}
