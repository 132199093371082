import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../modules'
import { logoutRequest } from '../../../modules/Session'

export const mapStateToProps = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const session = useSelector((state: ApplicationState) => state.session)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isOpenDrawer = useSelector(
    (state: ApplicationState) => state.application.isOpenDrawer
  )
  return { session, isOpenDrawer }
}

export const mapDispatchToProps = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => {
    return bindActionCreators({ logoutRequest }, dispatch)
  }, [dispatch])
}

export type PropsType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>
