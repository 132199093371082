import React from 'react'
import NoImage from '../../../../assets/images/no_image_96.png'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Footer } from '../../../organisms/Footer'

export const CoachChatIndexPage: React.FC<{}> = () => {
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="level is-mobile">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title is-size-5">質問チャット</h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <span>未読数</span>
                <span className="tag is-rounded is-normal">32</span>
              </div>
            </div>
          </div>
          <ul className="list is-shadowless">
            {[...Array(10)].map((_, index) => (
              <ListItem key={index}>
                <Link to="/coach/chats/1">
                  <div className="media" style={{ alignItems: 'center' }}>
                    <div className="media-left">
                      <figure className="image is-48x48">
                        <img src={NoImage} alt="Placeholder" />
                      </figure>
                    </div>
                    <div className="media-content">
                      <div className="content has-text-black">
                        <span>ユーザー名ああああああああ</span>
                      </div>
                    </div>
                    {index < 4 && (
                      <div className="media-right">
                        <span className="tag is-rounded is-normal">未読</span>
                      </div>
                    )}
                  </div>
                </Link>
              </ListItem>
            ))}
          </ul>
          <div className="has-text-centered">
            <button className="button is-rounded is-dark is-outlined">
              <span>質問終了済みのユーザーも表示する</span>
            </button>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container has-text-centered">
          <Link
            to="/coach"
            className="button is-dark is-outlined is-rounded is-fullwidth"
          >
            Coach TOPに戻る
          </Link>
        </div>
      </section>
      <Footer />
    </>
  )
}

const ListItem = styled.li`
  & + & {
    margin-top: 8px;
  }
`
