import firebase from 'firebase'
import { Article } from '../models'
import { NotFountError } from '../models/ErrorType'

export async function fetchLatestArticleApi(limit: number = 20) {
  const refs = firebase
    .firestore()
    .collection('articles')
    .limit(limit)
  const snapShot = await refs.get()
  const articles: Article[] = snapShot.docs.map((document: any) => {
    return {
      id: document.id,
      ...document.data()
    }
  })
  return articles
}

export async function fetchPopularArticleApi(limit: number = 20) {
  const refs = firebase
    .firestore()
    .collection('articles')
    .limit(limit)
  const snapShot = await refs.get()
  const articles: Article[] = snapShot.docs.map((document: any) => {
    return {
      id: document.id,
      ...document.data()
    }
  })
  return articles
}

export async function fetchArticleApi(
  categoryId: string,
  limit: number | undefined = undefined
) {
  const categoryRef = firebase
    .firestore()
    .collection('categories')
    .doc(categoryId)
  const refs = firebase
    .firestore()
    .collection('articles')
    .where('category.ref', '==', categoryRef)
  if (limit) {
    refs.limit(limit)
  }
  const snapShot = await refs.get()
  const articles: Article[] = snapShot.docs.map((document: any) => {
    return {
      id: document.id,
      ...document.data()
    }
  })
  return articles
}

export async function findArticleApi(id: string) {
  const document = await firebase
    .firestore()
    .collection('articles')
    .doc(id)
    .get()
  if (document.exists) {
    return { id: document.id, ...document.data() }
  } else {
    throw new NotFountError()
  }
}
