import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../modules'
import { push } from 'connected-react-router'

export const mapStateToProps = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isLoading = useSelector(
    (state: ApplicationState) => state.session.isLoading
  )
  return { isLoading }
}

export const mapDispatchToProps = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => {
    return bindActionCreators({ push }, dispatch)
  }, [dispatch])
}

export type PropsType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>
