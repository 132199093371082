import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import storage from 'redux-persist/lib/storage'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { persistReducer } from 'redux-persist'
import reducers from '../modules'
import rootSaga from '../sagas'

export const history = createBrowserHistory()

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['session'],
  blacklist: []
}

const sagaMiddleware = createSagaMiddleware()

const middlewares = [routerMiddleware(history), sagaMiddleware]

if (process.env.NODE_ENV !== 'production') {
  const logger = createLogger({
    diff: true,
    collapsed: true
  })
  middlewares.push(logger)
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const configStore = (preloadState?: any) => {
  const store = createStore(
    persistReducer(persistConfig, reducers(history)),
    preloadState,
    composeEnhancers(applyMiddleware(...middlewares))
  )

  sagaMiddleware.run(rootSaga)

  return store
}

export default configStore
