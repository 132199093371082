import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const BorderListItem = styled.li`
  padding: 16px 8px;
  border-bottom: 1px solid hsla(19, 5%, 89%, 1);
  &:first-child {
    border-top: 1px solid hsla(19, 5%, 89%, 1);
  }
`

export const ListTitle = styled.strong`
  display: block;
  margin-bottom: 4px;
`

export const ListBookCover = styled.div`
  width: 15%;
  @media screen and (max-width: 1023px) {
    width: 20%;
  }
`

export const Tag = styled.span`
  &&& {
    margin-bottom: 2px;
    font-size: 10px;
    height: auto;
  }
`

export const BlockLink = styled(Link)`
  &&& {
    min-height: 60px;
    border-width: 2px;
    font-size: 1.1rem;
    min-width: 80%;
  }
`

export const BlockButton = styled.button`
  &&& {
    min-height: 60px;
    border-width: 2px;
    font-size: 1.1rem;
    min-width: 80%;
  }
`
