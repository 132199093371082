import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { BlockButton } from '../../../assets/stylesheets/StyledComponents'

type Props = {
  price: number
  status: number
  changeNumber: (name: string, value: number) => void
  clickSubmit: () => void
}

const SERVICE_FEE = 0.2
const formatter = new Intl.NumberFormat('ja-JP', {
  style: 'currency',
  currency: 'JPY'
})

export const CoachPlanFormTemplate: React.FC<Props> = ({
  price,
  status,
  changeNumber,
  clickSubmit
}) => {
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    const value = parseInt(event.target.value)
    changeNumber(event.target.name, value)
  }

  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(event.target.value)
    if (isNaN(value)) {
      value = 0
    }
    changeNumber(event.target.name, value)
  }

  const handleBlurPrice = () => {
    if (price < 100) {
      changeNumber('price', 100)
    }
    if (price > 1000000) {
      changeNumber('price', 1000000)
    }
  }

  const handleClickSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    clickSubmit()
  }

  return (
    <>
      <form>
        <div className="field">
          <label className="label">
            <span>サポートする本</span>
            <RequiredLabel>必須</RequiredLabel>
          </label>
          <div className="control">
            <div className="select is-fullwidth">
              <select>
                <option>サンプル本A</option>
                <option>サンプル本B</option>
                <option>サンプル本C</option>
              </select>
            </div>
          </div>
        </div>
        <div className="field">
          <label className="label">
            <span>料金</span>
            <RequiredLabel>必須</RequiredLabel>
          </label>
          <div className="control">
            <input
              className="input has-text-right"
              name="price"
              type="number"
              placeholder="金額を入力"
              value={price}
              onChange={handleChangePrice}
              onBlur={handleBlurPrice}
            />
          </div>
          <div className="control">
            <small className="is-size-7">
              100円〜1,000,000円で設定可能。それ以外の金額は要お問い合わせ
            </small>
            <PriceBox className="box is-shadowless">
              <div className="level is-mobile">
                <div className="level-left">
                  <div className="level-item">
                    <small>手数料</small>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <span className="has-text-weight-bold">
                      {formatter.format(price * SERVICE_FEE)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="level is-mobile">
                <div className="level-left">
                  <div className="level-item">
                    <small>利益</small>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <span className="has-text-weight-bold">
                      {formatter.format(price - price * SERVICE_FEE)}
                    </span>
                  </div>
                </div>
              </div>
            </PriceBox>
            <Link to="" className="button is-text" target="_blank">
              手数料について
            </Link>
          </div>
        </div>
        <div className="field">
          <label className="label">
            <span>質問受付状況</span>
            <RequiredLabel>必須</RequiredLabel>
          </label>
          <div className="control">
            <div className="columns is-multiline is-mobile">
              <div className="column is-6">
                <label className="radio">
                  <InputRadio
                    name="status"
                    type="radio"
                    value="1"
                    onChange={handleChangeRadio}
                    checked={status === 1}
                  />
                  受付中
                </label>
              </div>
              <div className="column is-6">
                <label className="radio">
                  <InputRadio
                    name="status"
                    type="radio"
                    value="0"
                    onChange={handleChangeRadio}
                    checked={status === 0}
                  />
                  受付停止中
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="has-text-centered" style={{ marginTop: '2rem' }}>
          <StyledBlockButton
            className="button is-rounded is-info is-medium"
            style={{ marginBottom: '1rem' }}
            onClick={handleClickSubmit}
          >
            登録する
          </StyledBlockButton>
          <Link to="/coach" className="button is-text">
            キャンセル
          </Link>
        </div>
      </form>
    </>
  )
}

const RequiredLabel = styled.span`
  font-size: 10px;
  padding: 0 4px;
  margin-left: 8px;
  border: 1px solid #ec8890;
  border-radius: 8px;
  color: #ec8890;
`

const InputRadio = styled.input`
  margin-right: 4px;
`

const PriceBox = styled.div`
  &&& {
    margin-top: 0.75rem;
    border: 1px solid #0f0836;
    margin-bottom: 0;
  }
`

const StyledBlockButton = styled(BlockButton)`
  &&& {
    min-height: auto;
  }
`
