import { call, put, takeLatest } from 'redux-saga/effects'
import {
  Actions,
  ActionTypes,
  fetchLatestArticleFailure,
  fetchLatestArticleSuccess,
  fetchPopularArticleFailure,
  fetchPopularArticleSuccess
} from '../modules/Article'
import {
  fetchLatestArticleApi,
  fetchPopularArticleApi
} from '../apis/ArticleApi'

function* fetchLatestArticleAction(action: Actions) {
  try {
    const articles = yield call(fetchLatestArticleApi)
    yield put(fetchLatestArticleSuccess(articles))
  } catch (e) {
    yield put(fetchLatestArticleFailure(new Error(e.code)))
  }
}

function* fetchPopularArticleAction(action: Actions) {
  try {
    const articles = yield call(fetchPopularArticleApi)
    yield put(fetchPopularArticleSuccess(articles))
  } catch (e) {
    yield put(fetchPopularArticleFailure(new Error(e.code)))
  }
}

export function* watchArticleAction() {
  yield takeLatest(
    ActionTypes.FETCH_LATEST_ARTICLE_REQUEST,
    fetchLatestArticleAction
  )
  yield takeLatest(
    ActionTypes.FETCH_POPULAR_ARTICLE_REQUEST,
    fetchPopularArticleAction
  )
}
