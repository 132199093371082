import React from 'react'
import { Link } from 'react-router-dom'
import {
  BorderListItem,
  ListTitle
} from '../../../../assets/stylesheets/StyledComponents'
import { Footer } from '../../../organisms/Footer'

export const CoachQaIndexPage: React.FC<{}> = () => {
  return (
    <>
      <div
        className="section has-background-primary"
        style={{ paddingTop: '0', paddingBottom: '8px' }}
      >
        <div className="container has-text-centered">
          <h1 className="subtitle is-size-5 has-text-white">
            コース名あああああああああああああああ
          </h1>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <h2 className="subtitle">解説・QA記事一覧</h2>
          <ul className="list is-shadowless is-radiusless">
            {[...Array(5)].map((_, i) => (
              <BorderListItem key={i}>
                <Link to="/qa/1">
                  <div className="media">
                    <div className="media-content has-text-black">
                      <ListTitle>
                        あれ声が遅れているよ？スイングも遅れているよ？ラウンドスピードを守…
                      </ListTitle>
                      <small className="has-text-grey">20XX/MM/DD</small>
                    </div>
                  </div>
                </Link>
              </BorderListItem>
            ))}
          </ul>
          <div className="has-text-centered">
            <button className="button is-rounded is-dark is-outlined">
              <span className="icon">
                <i className="material-icons">keyboard_arrow_down</i>
              </span>
              <span>もっと見る</span>
            </button>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container has-text-centered">
          <Link
            to="/coach"
            className="button is-dark is-outlined is-rounded is-fullwidth"
          >
            TOPに戻る
          </Link>
        </div>
      </section>
      <Footer />
    </>
  )
}
