import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { PropsType } from './Container'
import { useInputField } from '../../../../hooks/FormFieldHook'
import { Loading } from '../../../molecules/Loading'
import { ErrorMessage } from '../../../atoms/ErrorMessage'
import { Footer } from '../../../organisms/Footer'

const Presenter: React.FC<PropsType> = ({
  isLoading,
  error,
  signupRequest
}) => {
  const [name, setName] = useInputField()
  const [email, setEmail] = useInputField()
  const [password, setPassword] = useInputField()
  const handleClickFacebook = (e: React.MouseEvent) => {
    e.preventDefault()
    signupRequest('Facebook', '', '', '')
  }
  const handleClickTwitter = (e: React.MouseEvent) => {
    e.preventDefault()
    signupRequest('Twitter', '', '', '')
  }
  const handleClickSubmit = (e: React.MouseEvent) => {
    e.preventDefault()
    signupRequest('Password', name, email, password)
  }
  return (
    <>
      <div className="section">
        <div className="container">
          <div className="column is-6-tablet is-offset-3-tablet">
            <div className="box">
              <h1 className="title is-size-4 has-text-centered">SIGN UP</h1>
              <ErrorMessage type="SIGNIN" error={error} />
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  <form>
                    <div className="field">
                      <div className="control">
                        <label className="label is-size-7">
                          ニックネーム（任意）
                        </label>
                        <input
                          className="input is-small"
                          type="text"
                          name="name"
                          placeholder="例：タスカリー"
                          value={name}
                          onChange={setName}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <label className="label is-size-7">
                          メールアドレス
                        </label>
                        <input
                          className="input is-small"
                          type="text"
                          name="email"
                          placeholder="例：mail@honq.com"
                          value={email}
                          onChange={setEmail}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <label className="label is-size-7">パスワード</label>
                        <input
                          className="input is-small"
                          type="password"
                          name="password"
                          placeholder="例：6文字以上の英数字"
                          value={password}
                          onChange={setPassword}
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="button is-block is-primary is-fullwidth"
                      onClick={handleClickSubmit}
                    >
                      登録する
                    </button>
                  </form>
                  <hr />
                  <SubTitle className="subtitle is-size-6">
                    ソーシャルアカウントで登録
                  </SubTitle>
                  <div className="buttons">
                    <button
                      className="button is-info is-fullwidth"
                      onClick={handleClickFacebook}
                    >
                      Facebookでログイン
                    </button>
                    <button
                      className="button is-info is-fullwidth"
                      onClick={handleClickTwitter}
                    >
                      Twitterでログイン
                    </button>
                  </div>
                  <hr />
                  <Link
                    to="/login"
                    className="button is-white is-fullwidth has-text-centered is-small"
                  >
                    アカウントをお持ちの方はこちら
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

const SubTitle = styled.div`
  &&& {
    margin-bottom: 1rem;
    text-align: center;
  }
`

export default Presenter
