import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { Category } from '../../../../../models'
import { ApplicationState } from '../../../../../modules'

export const mapStateToProps = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const categories = useSelector((state: ApplicationState) =>
    Object.values(state.category.categories).reduce(
      (result: { [key: string]: Category[] }, current) => ({
        ...result,
        [current.classification]: [
          ...(result[current.classification] || []),
          current
        ]
      }),
      {}
    )
  )
  return { categories }
}

export const mapDispatchToProps = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => {
    return bindActionCreators({}, dispatch)
  }, [dispatch])
}

export type PropsType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>
