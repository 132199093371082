import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import NoImage from '../../../assets/images/no_image_96.png'
import { Link } from 'react-router-dom'
import { PropsType } from './Container'

const Presenter: React.FC<PropsType> = ({
  session,
  isOpenDrawer,
  logoutRequest
}) => {
  const handleClickLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    logoutRequest()
  }
  const drawerClass = classNames({ shown: isOpenDrawer })
  return (
    <>
      <DrawerMenu className={drawerClass}>
        <section
          className="section has-background-primary"
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
        >
          <div className="container">
            <AlignItemsCenterMedia className="media">
              {session.isLoggedIn ? (
                <>
                  <div className="media-left">
                    <figure className="image is-32x32">
                      <img src={NoImage} alt="avatar" />
                    </figure>
                  </div>
                  <div className="media-content has-text-white">
                    <span>{session.name}</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="media-left" />
                  <div className="media-content has-text-white">
                    <span>ゲストユーザーさん</span>
                  </div>
                </>
              )}
            </AlignItemsCenterMedia>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <aside className="menu">
              <p className="menu-label">General</p>
              <ul className="menu-list">
                <li>
                  <MenuLink to="/" className="button is-text is-fullwidth">
                    <span className="icon is-small">
                      <i className="material-icons">search</i>
                    </span>
                    <span>本を検索する</span>
                  </MenuLink>
                </li>
                {session.isLoggedIn && (
                  <>
                    <li>
                      <MenuLink
                        to="/me"
                        className="button is-text is-fullwidth"
                      >
                        <span className="icon is-small">
                          <i className="material-icons-outlined">home</i>
                        </span>
                        <span>質問リスト</span>
                      </MenuLink>
                    </li>
                    <li>
                      <MenuLink
                        to="/me"
                        className="button is-text is-fullwidth"
                      >
                        <span className="icon is-small">
                          <i className="material-icons-outlined">home</i>
                        </span>
                        <span>投稿記事</span>
                      </MenuLink>
                    </li>
                  </>
                )}
              </ul>
              {session.isLoggedIn && session.isCoach && (
                <>
                  <p className="menu-label">Coach</p>
                  <ul className="menu-list">
                    <li>
                      <MenuLink
                        to="/coach"
                        className="button is-text is-fullwidth"
                      >
                        <span className="icon is-small">
                          <i className="material-icons-outlined">house</i>
                        </span>
                        <span>運営用TOP</span>
                      </MenuLink>
                    </li>
                    <li>
                      <MenuLink
                        to="/coach/chats"
                        className="button is-text is-fullwidth"
                      >
                        <span className="icon is-small">
                          <i className="material-icons-outlined">
                            question_answer
                          </i>
                        </span>
                        <span>質問チャット</span>
                      </MenuLink>
                    </li>
                  </ul>
                </>
              )}
              <p className="menu-label">Setting</p>
              <ul className="menu-list">
                {session.isLoggedIn && (
                  <li>
                    <MenuLink to="/" className="button is-text is-fullwidth">
                      <span className="icon is-small">
                        <i className="material-icons-outlined">
                          settings_applications
                        </i>
                      </span>
                      <span>設定</span>
                    </MenuLink>
                  </li>
                )}

                <li>
                  <MenuLink to="/" className="button is-text is-fullwidth">
                    <span className="icon is-small">
                      <i className="material-icons-outlined">send</i>
                    </span>
                    <span>お問い合わせ</span>
                  </MenuLink>
                </li>
                {session.isLoggedIn && (
                  <li>
                    <MenuLink
                      to="/"
                      className="button is-text is-fullwidth"
                      onClick={handleClickLogout}
                    >
                      <span className="icon is-small">
                        <i className="material-icons-outlined">
                          flight_takeoff
                        </i>
                      </span>
                      <span>ログアウト</span>
                    </MenuLink>
                  </li>
                )}
              </ul>
            </aside>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="buttons">
              {session.isLoggedIn ? (
                <>
                  {!session.isCoach && (
                    <Link
                      to="/"
                      className="button is-primary is-outlined is- is-fullwidth"
                    >
                      コーチを始める
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link
                    to="/signup"
                    className="button is-primary is-outlined is- is-fullwidth"
                  >
                    会員登録
                  </Link>
                  <Link
                    to="/login"
                    className="button is-primary is-outlined is-fullwidth"
                  >
                    ログイン
                  </Link>
                </>
              )}
            </div>
          </div>
        </section>
      </DrawerMenu>
    </>
  )
}

const DrawerMenu = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  right: auto;
  z-index: 1;
  backface-visibility: hidden;
  will-change: transform;
  width: 260px;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  transform: translate3d(-260px, 0, 0);
  &.shown {
    transform: translate3d(0, 0, 0);
  }
`

const AlignItemsCenterMedia = styled.div`
  &&& {
    align-items: center;
  }
`

const MenuLink = styled(Link)`
  &&& {
    text-decoration: none;
    justify-content: flex-start;
  }
`

export default Presenter
