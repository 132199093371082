import React, { useState } from 'react'
import styled from 'styled-components'

type Props = {
  expand?: boolean
  header: React.ReactElement
  children: React.ReactNode
}

type StyledProps = {
  active: boolean
}

const Accordion: React.FC<Props> = ({ expand = false, header, children }) => {
  const [active, setActive] = useState(expand)
  return (
    <>
      <AccordionStyle>
        <AccordionHeader onClick={() => setActive(!active)}>
          {header}
          <ExpandIcon className="icon" active={active} />
        </AccordionHeader>
        <AccordionBody active={active}>
          <div>{children}</div>
        </AccordionBody>
      </AccordionStyle>
    </>
  )
}

export default Accordion

const AccordionStyle = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  font-size: 1rem;
`

const AccordionHeader = styled.div`
  align-items: center;
  background-color: whitesmoke;
  color: #0f0835;
  display: flex;
  justify-content: space-between;
  line-height: 1.25;
  padding: 0.75em;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
`

const ExpandIcon = styled.span`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 0.75em;
  outline: none;
  position: relative;
  vertical-align: top;
  &:before {
    color: #0f0835;
    display: flex;
    left: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    line-height: 0;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-family: 'Material Icons';
    ${(props: StyledProps) =>
      props.active ? "content: 'arrow_drop_up'" : "content: 'arrow_drop_down'"}
  }
`

const AccordionBody = styled.div`
  overflow-y: hidden;
  max-height: 0;
  color: #4a4a4a;
  border: 1px solid #dbdbdb;
  border-top: none;
  -webkit-transition: max-height 0.2s ease;
  -moz-transition: max-height 0.2s ease;
  -o-transition: max-height 0.2s ease;
  transition: max-height 0.2s ease;
  ${(props: StyledProps) => (props.active ? 'max-height: 100em' : '')}
`
