import Redux from 'redux'
import { Article } from '../models'

export type State = {
  latestArticles: Article[]
  popularArticles: Article[]
  articles: Article[]
  article: Article | undefined
}

const initialState: State = {
  latestArticles: [],
  popularArticles: [],
  articles: [],
  article: undefined
}

export enum ActionTypes {
  FETCH_LATEST_ARTICLE_REQUEST = 'FETCH_LATEST_ARTICLE_REQUEST',
  FETCH_LATEST_ARTICLE_SUCCESS = 'FETCH_LATEST_ARTICLE_SUCCESS',
  FETCH_LATEST_ARTICLE_FAILURE = 'FETCH_LATEST_ARTICLE_FAILURE',
  FETCH_POPULAR_ARTICLE_REQUEST = 'FETCH_POPULAR_ARTICLE_REQUEST',
  FETCH_POPULAR_ARTICLE_SUCCESS = 'FETCH_POPULAR_ARTICLE_SUCCESS',
  FETCH_POPULAR_ARTICLE_FAILURE = 'FETCH_POPULAR_ARTICLE_FAILURE',
  FETCH_ARTICLE_REQUEST = 'FETCH_ARTICLE_REQUEST',
  FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS',
  FETCH_ARTICLE_FAILURE = 'FETCH_ARTICLE_FAILURE',
  FIND_ARTICLE_REQUEST = 'FIND_ARTICLE_REQUEST',
  FIND_ARTICLE_SUCCESS = 'FIND_ARTICLE_SUCCESS',
  FIND_ARTICLE_FAILURE = 'FIND_ARTICLE_FAILURE'
}

export const reducer: Redux.Reducer<State, Actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.FETCH_LATEST_ARTICLE_REQUEST: {
      return {
        ...state,
        latestArticles: []
      }
    }
    case ActionTypes.FETCH_LATEST_ARTICLE_SUCCESS: {
      return {
        ...state,
        latestArticles: action.payload.articles
      }
    }
    case ActionTypes.FETCH_POPULAR_ARTICLE_REQUEST: {
      return {
        ...state,
        popularArticles: []
      }
    }
    case ActionTypes.FETCH_POPULAR_ARTICLE_SUCCESS: {
      return {
        ...state,
        popularArticles: action.payload.articles
      }
    }
    case ActionTypes.FETCH_ARTICLE_REQUEST: {
      return {
        ...state,
        articles: []
      }
    }
    case ActionTypes.FETCH_ARTICLE_SUCCESS: {
      return {
        ...state,
        articles: action.payload.articles
      }
    }
    case ActionTypes.FIND_ARTICLE_SUCCESS: {
      return {
        ...state,
        article: action.payload.article
      }
    }
    case ActionTypes.FETCH_LATEST_ARTICLE_FAILURE:
    case ActionTypes.FETCH_POPULAR_ARTICLE_FAILURE:
    case ActionTypes.FETCH_ARTICLE_FAILURE:
    case ActionTypes.FIND_ARTICLE_REQUEST:
    case ActionTypes.FIND_ARTICLE_FAILURE: {
      return {
        ...state
      }
    }
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action
      return state
  }
}

export const fetchLatestArticleRequest = () => ({
  type: ActionTypes.FETCH_LATEST_ARTICLE_REQUEST as ActionTypes.FETCH_LATEST_ARTICLE_REQUEST,
  payload: {},
  meta: {},
  error: false
})

export const fetchLatestArticleSuccess = (articles: Article[]) => ({
  type: ActionTypes.FETCH_LATEST_ARTICLE_SUCCESS as ActionTypes.FETCH_LATEST_ARTICLE_SUCCESS,
  payload: { articles },
  meta: {},
  error: false
})

export const fetchLatestArticleFailure = (error: Error) => ({
  type: ActionTypes.FETCH_LATEST_ARTICLE_FAILURE as ActionTypes.FETCH_LATEST_ARTICLE_FAILURE,
  payload: error,
  meta: {},
  error: true
})

export const fetchPopularArticleRequest = () => ({
  type: ActionTypes.FETCH_POPULAR_ARTICLE_REQUEST as ActionTypes.FETCH_POPULAR_ARTICLE_REQUEST,
  payload: {},
  meta: {},
  error: false
})

export const fetchPopularArticleSuccess = (articles: Article[]) => ({
  type: ActionTypes.FETCH_POPULAR_ARTICLE_SUCCESS as ActionTypes.FETCH_POPULAR_ARTICLE_SUCCESS,
  payload: { articles },
  meta: {},
  error: false
})

export const fetchPopularArticleFailure = (error: Error) => ({
  type: ActionTypes.FETCH_POPULAR_ARTICLE_FAILURE as ActionTypes.FETCH_POPULAR_ARTICLE_FAILURE,
  payload: error,
  meta: {},
  error: true
})

export const fetchArticleRequest = (categoryId: string) => ({
  type: ActionTypes.FETCH_ARTICLE_REQUEST as ActionTypes.FETCH_ARTICLE_REQUEST,
  payload: { categoryId },
  meta: {},
  error: false
})

export const fetchArticleSuccess = (articles: Article[]) => ({
  type: ActionTypes.FETCH_ARTICLE_SUCCESS as ActionTypes.FETCH_ARTICLE_SUCCESS,
  payload: { articles },
  meta: {},
  error: false
})

export const fetchArticleFailure = (error: Error) => ({
  type: ActionTypes.FETCH_ARTICLE_FAILURE as ActionTypes.FETCH_ARTICLE_FAILURE,
  payload: error,
  meta: {},
  error: true
})

export const findArticleRequest = (id: string) => ({
  type: ActionTypes.FIND_ARTICLE_REQUEST as ActionTypes.FIND_ARTICLE_REQUEST,
  payload: { id },
  meta: {},
  error: false
})

export const findArticleSuccess = (article: Article) => ({
  type: ActionTypes.FIND_ARTICLE_SUCCESS as ActionTypes.FIND_ARTICLE_SUCCESS,
  payload: { article },
  meta: {},
  error: false
})

export const findArticleFailure = (error: Error) => ({
  type: ActionTypes.FIND_ARTICLE_FAILURE as ActionTypes.FIND_ARTICLE_FAILURE,
  payload: error,
  meta: {},
  error: true
})

export type Actions = ReturnType<
  | typeof fetchLatestArticleRequest
  | typeof fetchLatestArticleSuccess
  | typeof fetchLatestArticleFailure
  | typeof fetchPopularArticleRequest
  | typeof fetchPopularArticleSuccess
  | typeof fetchPopularArticleFailure
  | typeof fetchArticleRequest
  | typeof fetchArticleSuccess
  | typeof fetchArticleFailure
  | typeof findArticleRequest
  | typeof findArticleSuccess
  | typeof findArticleFailure
>
