import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CoachPlanFormTemplate } from '../../../templates/Coach/CoachPlanFormTemplate'
import { Footer } from '../../../organisms/Footer'

export const CoachPlanCreatePage: React.FC = () => {
  const history = useHistory()

  const [state, setState] = useState({
    price: 100,
    status: 1
  })

  const handleChangeNumber = (name: string, value: number) => {
    setState({ ...state, [name]: value })
  }
  const handleClickSubmit = () => {
    history.push('/coach')
  }
  return (
    <>
      <section className="section">
        <div className="container">
          <h1 className="title is-size-5 has-text-centered">プラン追加</h1>
          <CoachPlanFormTemplate
            price={state.price}
            status={state.status}
            changeNumber={handleChangeNumber}
            clickSubmit={handleClickSubmit}
          />
        </div>
      </section>
      <Footer />
    </>
  )
}
