import Redux from 'redux'
import { Coach } from '../models'

export type State = {
  coaches: Coach[]
  coach: Coach | undefined
}

const initialState: State = {
  coaches: [],
  coach: undefined
}

export enum ActionTypes {
  FETCH_COACH_REQUEST = 'FETCH_COACH_REQUEST',
  FETCH_COACH_SUCCESS = 'FETCH_COACH_SUCCESS',
  FETCH_COACH_FAILURE = 'FETCH_COACH_FAILURE',
  FIND_COACH_REQUEST = 'FIND_COACH_REQUEST',
  FIND_COACH_SUCCESS = 'FIND_COACH_SUCCESS',
  FIND_COACH_FAILURE = 'FIND_COACH_FAILURE'
}

export const reducer: Redux.Reducer<State, Actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.FETCH_COACH_REQUEST: {
      return {
        ...state,
        coaches: []
      }
    }
    case ActionTypes.FETCH_COACH_SUCCESS: {
      return {
        ...state,
        coaches: action.payload.coaches
      }
    }
    case ActionTypes.FIND_COACH_SUCCESS: {
      return {
        ...state,
        coach: action.payload.coach
      }
    }
    case ActionTypes.FETCH_COACH_FAILURE:
    case ActionTypes.FIND_COACH_REQUEST:
    case ActionTypes.FIND_COACH_FAILURE: {
      return {
        ...state
      }
    }
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action
      return state
  }
}

export const fetchCoachRequest = (bookId: string) => ({
  type: ActionTypes.FETCH_COACH_REQUEST as ActionTypes.FETCH_COACH_REQUEST,
  payload: { bookId },
  meta: {},
  error: false
})

export const fetchCoachSuccess = (coaches: Coach[]) => ({
  type: ActionTypes.FETCH_COACH_SUCCESS as ActionTypes.FETCH_COACH_SUCCESS,
  payload: { coaches },
  meta: {},
  error: false
})

export const fetchCoachFailure = (error: Error) => ({
  type: ActionTypes.FETCH_COACH_FAILURE as ActionTypes.FETCH_COACH_FAILURE,
  payload: error,
  meta: {},
  error: true
})

export const findCoachRequest = (id: string) => ({
  type: ActionTypes.FIND_COACH_REQUEST as ActionTypes.FIND_COACH_REQUEST,
  payload: { id },
  meta: {},
  error: false
})

export const findCoachSuccess = (coach: Coach) => ({
  type: ActionTypes.FIND_COACH_SUCCESS as ActionTypes.FIND_COACH_SUCCESS,
  payload: { coach },
  meta: {},
  error: false
})

export const findCoachFailure = (error: Error) => ({
  type: ActionTypes.FIND_COACH_FAILURE as ActionTypes.FIND_COACH_FAILURE,
  payload: error,
  meta: {},
  error: true
})

export type Actions = ReturnType<
  | typeof fetchCoachRequest
  | typeof fetchCoachSuccess
  | typeof fetchCoachFailure
  | typeof findCoachRequest
  | typeof findCoachSuccess
  | typeof findCoachFailure
>
