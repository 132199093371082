import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { BlockButton } from '../../../../assets/stylesheets/StyledComponents'

export const RequestSupportBookPage: React.FC<{}> = () => {
  const history = useHistory()
  const [state, setState] = useState({
    title: '',
    author: '',
    publisher: '',
    releaseAt: '',
    remarks: '',
    category: '',
    identification: '1'
  })

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, identification: event.target.value })
  }

  const handleClickSubmit = () => {
    history.push('/coach')
  }

  return (
    <>
      <section className="section">
        <div className="container">
          <h1 className="title is-size-5 has-text-centered">
            サポートする本の追加申請
          </h1>
          <form>
            <div className="field">
              <div className="label">
                <span>サポートする本</span>
              </div>
              <div className="field">
                <label className="label">
                  <small>タイトル</small>
                  <RequiredLabel>必須</RequiredLabel>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="title"
                    value={state.title}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">
                  <small>著者名</small>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="author"
                    value={state.author}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">
                  <small>出版社</small>
                  <RequiredLabel>必須</RequiredLabel>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="publisher"
                    value={state.publisher}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">
                  <small>発売日</small>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="releaseAt"
                    value={state.releaseAt}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">
                  <small>備考：所持している本の版など</small>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="remarks"
                    value={state.remarks}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">
                  <small>カテゴリ新規作成希望</small>
                </label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    name="category"
                    value={state.category}
                    onChange={handleChangeInput}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">
                  <small>本の著者本人か？</small>
                </label>
                <div className="control">
                  <div className="columns is-multiline is-mobile">
                    <div className="column is-6">
                      <label className="radio">
                        <InputRadio
                          type="radio"
                          value="1"
                          onChange={handleChangeRadio}
                          checked={state.identification === '1'}
                        />
                        本人ではない
                      </label>
                    </div>
                    <div className="column is-6">
                      <label className="radio">
                        <InputRadio
                          type="radio"
                          value="2"
                          onChange={handleChangeRadio}
                          checked={state.identification === '2'}
                        />
                        本人
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="has-text-centered" style={{ marginTop: '2rem' }}>
              <StyledBlockButton
                className="button is-rounded is-info is-medium"
                style={{ marginBottom: '1rem' }}
                onClick={handleClickSubmit}
              >
                申請する
              </StyledBlockButton>
              <Link to="/coach" className="button is-text">
                キャンセル
              </Link>
            </div>
          </form>
        </div>
      </section>
      <footer className="footer">
        <div className="content has-text-centered">
          <p>©︎honq.com</p>
        </div>
      </footer>
    </>
  )
}

const RequiredLabel = styled.span`
  font-size: 10px;
  padding: 0 4px;
  margin-left: 8px;
  border: 1px solid #ec8890;
  border-radius: 8px;
  color: #ec8890;
`

const InputRadio = styled.input`
  margin-right: 4px;
`

const StyledBlockButton = styled(BlockButton)`
  &&& {
    min-height: auto;
  }
`
