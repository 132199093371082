import React from 'react'
import * as Container from './Container'
import * as LatestContainer from './LatestContainer'
import * as PopularContainer from './PopularContainer'
import Component from './Component'

export type Props =
  | Container.PropsType
  | LatestContainer.PropsType
  | PopularContainer.PropsType

export const ArticleList = (ownProps: any): JSX.Element => {
  const props = {
    ...Container.mapStateToProps(),
    ...Container.mapDispatchToProps(),
    ...ownProps
  }
  return <Component {...props} />
}

export const LatestArticleList = (ownProps: any): JSX.Element => {
  const props = {
    ...LatestContainer.mapStateToProps(),
    ...LatestContainer.mapDispatchToProps(),
    ...ownProps
  }
  return <Component {...props} />
}

export const PopularArticleList = (ownProps: any): JSX.Element => {
  const props = {
    ...PopularContainer.mapStateToProps(),
    ...PopularContainer.mapDispatchToProps(),
    ...ownProps
  }
  return <Component {...props} />
}
