import React, { useState } from 'react'
import classNames from 'classnames'
import { useHistory } from 'react-router'
import { CoachArticleFormTemplate } from '../../../templates/Coach/CoachArticleFormTemplate'
import { Footer } from '../../../organisms/Footer'

export const CoachArticleEditPage: React.FC<{}> = () => {
  const history = useHistory()
  const [state, setState] = useState({
    title: '',
    body: ''
  })
  const [isShownDialog, setIsShownDialog] = useState(false)

  const handleChangeInput = (name: string, value: string) => {
    setState({ ...state, [name]: value })
  }
  const handleCancelButton = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    if (state.title === '' && state.body === '') {
      history.push('/coach')
    } else {
      setIsShownDialog(true)
    }
  }
  const handleDeleteButton = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    history.push('/coach')
  }
  const handlePublishButton = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    history.push('/coach')
  }
  const handleCancelButtonInModal = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setIsShownDialog(false)
  }
  const handleDropButtonInModal = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    history.push('/coach')
  }
  const handleDraftButtonInModal = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    history.push('/coach')
  }

  const modalClassName = classNames('modal', { 'is-active': isShownDialog })

  return (
    <>
      <div
        className="section has-background-primary"
        style={{ paddingTop: '0', paddingBottom: '8px' }}
      >
        <div className="container">
          <div className="level is-mobile">
            <div className="level-item">
              <button
                className="button"
                style={{ flex: 1 }}
                onClick={handleCancelButton}
              >
                {state.title === '' && state.body === ''
                  ? 'キャンセル'
                  : '下書き保存'}
              </button>
            </div>
            <div className="level-item">
              <button
                className="button"
                style={{ flex: 1 }}
                onClick={handleDeleteButton}
              >
                削除
              </button>
            </div>
            <div className="level-item">
              <button
                className="button"
                style={{ flex: 1 }}
                onClick={handlePublishButton}
              >
                公開
              </button>
            </div>
          </div>
        </div>
      </div>
      <CoachArticleFormTemplate
        title={state.title}
        body={state.body}
        changeInput={handleChangeInput}
      />
      <Footer />
      <div className={modalClassName}>
        <div className="modal-background" />
        <div className="modal-content">
          <div className="box" style={{ margin: '1rem' }}>
            <div className="content">
              <p>下書きとして保存しますか？</p>
            </div>
            <div className="buttons">
              <button className="button" onClick={handleDropButtonInModal}>
                破棄する
              </button>
              <button className="button" onClick={handleDraftButtonInModal}>
                保存する
              </button>
              <button className="button" onClick={handleCancelButtonInModal}>
                キャンセル
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
