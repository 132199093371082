import React from 'react'

export const NotFoundPage: React.FC<{}> = () => {
  return (
    <>
      <section className="section">
        <div className="container">
          <h1 className="has-text-centered">Page Not Found</h1>
        </div>
      </section>
    </>
  )
}
