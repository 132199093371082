import React from 'react'

type Props = {
  title: string
  body: string
  changeInput: (name: string, value: string) => void
}

export const CoachArticleFormTemplate: React.FC<Props> = ({
  title,
  body,
  changeInput
}) => {
  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.persist()
    changeInput(event.target.name, event.target.value)
  }
  return (
    <>
      <section
        className="section has-background-grey-light"
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
      >
        <div className="container">
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">記事のイメージ</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <button className="button">設定する</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="field">
            <div className="control">
              <input
                name="title"
                className="input"
                type="text"
                placeholder="タイトル"
                value={title}
                onChange={handleChangeInput}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <textarea
                name="body"
                className="textarea has-fixed-size"
                rows={10}
                placeholder="本文"
                value={body}
                onChange={handleChangeInput}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
