import React from 'react'
import Component from './Component'
import { mapDispatchToProps, mapStateToProps } from './Container'
import { useParams } from 'react-router-dom'
import { useLoadPageHook } from '../../../../../../hooks/LoadPageHook'

export const BookShowPage = (ownProps: any): JSX.Element => {
  const props = { ...mapStateToProps(), ...mapDispatchToProps(), ...ownProps }

  const { book_id } = useParams()
  return useLoadPageHook(<Component {...props} />, 'USER_BOOK_SHOW', book_id)
}
