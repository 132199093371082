import firebase from 'firebase'
import { Categories, Category } from '../models'
import { NotFountError } from '../models/ErrorType'

export async function fetchPopularCategoryApi(limit: number = 20) {
  const snapShot = await firebase
    .firestore()
    .collection('categories')
    .limit(limit)
    .get()
  const categories: Category[] = snapShot.docs.map((document: any) => {
    return {
      id: document.id,
      ...document.data()
    }
  })
  return categories
}

export async function fetchCategoryApi() {
  const snapShot = await firebase
    .firestore()
    .collection('categories')
    .get()
  const categories: Categories = snapShot.docs.reduce(
    (result: { [key: string]: Category }, document: any) => {
      result[document.id] = {
        id: document.id,
        ...document.data()
      }
      return result
    },
    {}
  )
  return categories
}

export async function findCategoryApi(id: string) {
  const document = await firebase
    .firestore()
    .collection('categories')
    .doc(id)
    .get()
  if (document.exists) {
    return { id: document.id, prBooks: [], sites: [], ...document.data() }
  } else {
    throw new NotFountError()
  }
}
