import React from 'react'
import { Link } from 'react-router-dom'
import NoImage from '../../assets/images/no_image_96.png'
import {
  BorderListItem,
  ListTitle
} from '../../assets/stylesheets/StyledComponents'
import styled from 'styled-components'
import { Footer } from '../organisms/Footer'

export const MePage: React.FC<{}> = () => {
  return (
    <>
      <div
        className="section has-background-primary"
        style={{ paddingTop: '0', paddingBottom: '10px' }}
      >
        <div className="container has-text-centered">
          <h1 className="subtitle is-size-5 has-text-white">マイページ</h1>
        </div>
      </div>
      {/*<section className="section">*/}
      {/*  <div className="container">*/}
      {/*    <h2 className="title is-4">マイコース</h2>*/}
      {/*    <ul className="list is-shadowless is-radiusless">*/}
      {/*      {[...Array(5)].map((_, i) => (*/}
      {/*        <BorderListItem key={i}>*/}
      {/*          <Link to="/courses/1">*/}
      {/*            <div className="media">*/}
      {/*              <div className="media-left">*/}
      {/*                <figure className="image is-48x48">*/}
      {/*                  <img src={NoImage} alt="Placeholder" />*/}
      {/*                </figure>*/}
      {/*              </div>*/}
      {/*              <div className="media-content">*/}
      {/*                <div className="content has-text-black">*/}
      {/*                  <ListTitle>スライス撲滅を始めよう</ListTitle>*/}
      {/*                  <small>開講者：TAKAHASHI</small>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </Link>*/}
      {/*        </BorderListItem>*/}
      {/*      ))}*/}
      {/*    </ul>*/}
      {/*  </div>*/}
      {/*</section>*/}
      <section className="section">
        <div className="container">
          <div className="level is-mobile">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title is-size-5">質問チャット</h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <span>未読数</span>
                <span className="tag is-rounded is-normal">32</span>
              </div>
            </div>
          </div>
          <ul className="list is-shadowless">
            {[...Array(6)].map((_, index) => (
              <ListItem key={index}>
                <Link to="/chats/1">
                  <div className="media" style={{ alignItems: 'center' }}>
                    <div className="media-left">
                      <figure className="image is-48x48">
                        <img src={NoImage} alt="Placeholder" />
                      </figure>
                    </div>
                    <div className="media-content">
                      <div className="content has-text-black">
                        <span>ユーザー名ああああああああ</span>
                      </div>
                    </div>
                    {index < 4 && (
                      <div className="media-right">
                        <span className="tag is-rounded is-normal">未読</span>
                      </div>
                    )}
                  </div>
                </Link>
              </ListItem>
            ))}
          </ul>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <h2 className="title is-4">マイカテゴリ</h2>
          <div className="buttons">
            <Link
              to="/categories/1"
              className="button is-rounded is-light has-text-link"
            >
              Excel
            </Link>
            <Link
              to="/categories/1"
              className="button is-rounded is-light has-text-link"
            >
              PowerPoint
            </Link>
            <Link
              to="/categories/1"
              className="button is-rounded is-light has-text-link"
            >
              Word
            </Link>
            <Link
              to="/categories/1"
              className="button is-rounded is-light has-text-link"
            >
              資料作成
            </Link>
            <Link
              to="/categories/1"
              className="button is-rounded is-light has-text-link"
            >
              OneNote
            </Link>
            <Link
              to="/categories/1"
              className="button is-rounded is-light has-text-link"
            >
              Excel
            </Link>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <h2 className="title is-4">新着記事</h2>
          <ul className="list is-shadowless is-radiusless">
            {[...Array(5)].map((_, i) => (
              <BorderListItem key={i}>
                <Link to="/articles/1">
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-64x64">
                        <img src={NoImage} alt="avatar" />
                      </figure>
                    </div>
                    <div className="media-content has-text-black">
                      <ListTitle>
                        あれ声が遅れているよ？スイングも遅れているよ？ラウンドスピードを守…
                      </ListTitle>
                      <div className="level is-mobile">
                        <div className="level-left">
                          <div className="level-item">
                            <small>TAKAHASHI</small>
                          </div>
                        </div>
                        <div className="level-right">
                          <div className="level-item">
                            <small className="has-text-grey">20XX/MM/DD</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </BorderListItem>
            ))}
          </ul>
        </div>
      </section>
      <Footer />
    </>
  )
}

const ListItem = styled.li`
  & + & {
    margin-top: 8px;
  }
`
