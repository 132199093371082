import {
  call,
  put,
  take,
  takeLatest,
  fork,
  select,
  cancelled
} from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import {
  Actions,
  ActionTypes,
  signupRequest,
  signupSuccess,
  signupFailure,
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutRequest,
  logoutSuccess,
  logoutFailure,
  updateSession
} from '../modules/Session'
import firebase, { providers, serverTimestamp } from '../plugins/Firebase'
import { push } from 'connected-react-router'

function* checkRedirectResult() {
  const credential = yield firebase
    .auth()
    .getRedirectResult()
    .catch(() => ({ user: null }))
  const user = credential.user
  if (user) {
    const action = yield select(state => state.session.action)
    switch (action) {
      case 'SIGNUP': {
        yield firebase
          .firestore()
          .collection('users')
          .doc(credential.user.uid)
          .set(
            {
              name: user.displayName,
              photoUrl: user.photoURL,
              updatedAt: serverTimestamp
            },
            { merge: true }
          )
        yield put(updateSession(true))
        yield put(push('/'))
        break
      }
      case 'LOGIN': {
        if (
          credential.additionalUserInfo &&
          credential.additionalUserInfo.isNewUser
        ) {
          // TODO: ログイン時に新規ユーザーの場合は新規登録と同じフロー
          yield firebase
            .firestore()
            .collection('users')
            .doc(credential.user.uid)
            .set(
              {
                name: user.displayName,
                photoUrl: user.photoURL,
                updatedAt: serverTimestamp
              },
              { merge: true }
            )
          yield put(updateSession(true))
          yield put(push('/'))
        } else {
          yield put(updateSession(true))
          yield put(push('/'))
        }
        break
      }
      default: {
        yield put(logoutRequest())
      }
    }
  }
}

function authChannel() {
  return eventChannel(emit => {
    return firebase
      .auth()
      .onIdTokenChanged(user => emit({ user }), error => emit({ error }))
  })
}

function* checkAuthentication() {
  const channel = yield call(authChannel)
  try {
    while (true) {
      const { user } = yield take(channel)
      if (user) {
        const document = yield firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
        if (document.exists) {
          yield put(
            updateSession(
              true,
              document.id,
              document.get('name'),
              document.get('email'),
              document.get('photoUrl'),
              document.get('isCoach')
            )
          )
        }
      } else {
        yield put(updateSession(false))
      }
    }
  } finally {
    if (yield cancelled()) {
      channel.close()
    }
  }
}

function* postSignupRequest(action: ReturnType<typeof signupRequest>) {
  try {
    const payload = action.payload
    switch (payload.provider) {
      case 'Facebook':
      case 'Twitter': {
        const provider = providers.get(payload.provider)
        if (provider) {
          yield firebase.auth().signInWithRedirect(provider)
        }
        break
      }
      case 'Password': {
        const credential = yield firebase
          .auth()
          .createUserWithEmailAndPassword(payload.email, payload.password)
        if (credential.user && payload.name !== '') {
          yield firebase
            .firestore()
            .collection('users')
            .doc(credential.user.uid)
            .set(
              {
                name: payload.name,
                photoUrl: '',
                updatedAt: serverTimestamp
              },
              { merge: true }
            )
        }
        yield put(signupSuccess())
        yield put(push('/'))
      }
    }
  } catch (e) {
    yield put(signupFailure(new Error(e.code)))
  }
}

function* postLoginAction(action: ReturnType<typeof loginRequest>) {
  try {
    const payload = action.payload
    switch (payload.provider) {
      case 'Facebook':
      case 'Twitter': {
        const provider = providers.get(payload.provider)
        if (provider) {
          yield firebase.auth().signInWithRedirect(provider)
        }
        break
      }
      case 'Password': {
        const payload = action.payload
        yield firebase
          .auth()
          .signInWithEmailAndPassword(payload.email, payload.password)
        yield put(loginSuccess())
        yield put(push('/'))
      }
    }
  } catch (e) {
    yield put(loginFailure(new Error(e.code)))
  }
}

function* postLogoutAction(action: Actions) {
  try {
    yield firebase.auth().signOut()
    yield put(logoutSuccess())
    yield put(push('/login'))
  } catch (e) {
    yield put(logoutFailure(new Error(e.code)))
  }
}

export function* watchSessionAction() {
  yield takeLatest(ActionTypes.SIGNUP_REQUEST, postSignupRequest)
  yield takeLatest(ActionTypes.LOGIN_REQUEST, postLoginAction)
  yield takeLatest(ActionTypes.LOGOUT_REQUEST, postLogoutAction)
  yield fork(checkRedirectResult)
  yield fork(checkAuthentication)
}
