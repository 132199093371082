import React from 'react'
import { Link } from 'react-router-dom'
import Accordion from '../../../../molecules/Accordion'
import styled from 'styled-components'
import { Footer } from '../../../../organisms/Footer'
import { PropsType } from './Container'

const Presenter: React.FC<PropsType> = ({ categories }) => {
  return (
    <>
      <div
        className="section has-background-primary"
        style={{ paddingTop: '0', paddingBottom: '10px' }}
      >
        <div className="container has-text-centered">
          <h1 className="subtitle is-size-5 has-text-white">
            学びたいカテゴリを探す
          </h1>
        </div>
      </div>
      {Object.keys(categories).length > 0 ? (
        <section className="accordions">
          {Object.keys(categories).map((key, index) => (
            <Accordion
              key={key}
              expand={index === 0}
              header={
                <p className="has-text-primary">
                  {key} ({categories[key].length})
                </p>
              }
            >
              <ul className="list is-shadowless is-radiusless">
                {categories[key].map(category => (
                  <AccordionList key={category.id} className="list-item">
                    <Link
                      to={`/categories/${category.id}`}
                      className="is-block"
                    >
                      {category.name} (140)
                    </Link>
                  </AccordionList>
                ))}
              </ul>
            </Accordion>
          ))}
        </section>
      ) : (
        <section className="section">
          <div className="container">
            <div className="has-text-centered has-text-grey">
              該当するデータがありません
            </div>
          </div>
        </section>
      )}

      <Footer />
    </>
  )
}

const AccordionList = styled.li`
  &&& {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`

export default Presenter
