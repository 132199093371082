import React, { useEffect } from 'react'
import { Notification } from '../components/organisms/Notification'
import { Header } from '../components/organisms/Header'
import { Drawer } from '../components/organisms/Drawer'
import { Pusher } from '../components/atoms/Pusher'
import { useLocation } from 'react-router-dom'

export const ApplicationLayout: React.FC<{}> = ({ children }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <Drawer />
      <Pusher>
        <Header />
        {children}
      </Pusher>
      <Notification />
    </>
  )
}
