import Redux from 'redux'
import { AuthProviders } from '../models'

export type State = {
  isLoggedIn: boolean
  id: string | undefined
  name: string | undefined
  email: string | undefined
  photoUrl: string | undefined
  isCoach: boolean
  action: undefined | 'LOGIN' | 'SIGNUP'
  isLoading: boolean
}

const initialState: State = {
  isLoggedIn: false,
  id: undefined,
  name: undefined,
  email: undefined,
  photoUrl: undefined,
  isCoach: false,
  action: undefined,
  isLoading: false
}

export enum ActionTypes {
  SIGNUP_REQUEST = 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS = 'SIGNUP_SUCCESS',
  SIGNUP_FAILURE = 'SIGNUP_FAILURE',
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'LOGOUT_FAILURE',
  UPDATE_SESSION = 'UPDATE_SESSION'
}

export const reducer: Redux.Reducer<State, Actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.SIGNUP_REQUEST: {
      return {
        ...state,
        action: 'SIGNUP',
        isLoading: true
      }
    }
    case ActionTypes.LOGIN_REQUEST: {
      return {
        ...state,
        action: 'LOGIN',
        isLoading: true
      }
    }
    case ActionTypes.SIGNUP_SUCCESS:
    case ActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
        action: undefined,
        isLoading: false
      }
    }
    case ActionTypes.SIGNUP_FAILURE:
    case ActionTypes.LOGIN_FAILURE: {
      return {
        ...state,
        isLoggedIn: false,
        action: undefined,
        isLoading: false
      }
    }
    case ActionTypes.LOGOUT_SUCCESS: {
      return {
        ...state,
        isLoggedIn: false
      }
    }
    case ActionTypes.LOGOUT_REQUEST:
    case ActionTypes.LOGOUT_FAILURE: {
      return {
        ...state
      }
    }
    case ActionTypes.UPDATE_SESSION: {
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
        id: action.payload.id,
        name: action.payload.name,
        email: action.payload.email,
        photoUrl: action.payload.photoUrl,
        isCoach: action.payload.isCoach,
        isLoading: false
      }
    }
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action
      return state
  }
}

export const signupRequest = (
  provider: AuthProviders,
  name: string,
  email: string,
  password: string
) => ({
  type: ActionTypes.SIGNUP_REQUEST as ActionTypes.SIGNUP_REQUEST,
  payload: { provider, name, email, password },
  meta: {},
  error: false
})

export const signupSuccess = () => ({
  type: ActionTypes.SIGNUP_SUCCESS as ActionTypes.SIGNUP_SUCCESS,
  payload: {},
  meta: {},
  error: false
})

export const signupFailure = (error: Error) => ({
  type: ActionTypes.SIGNUP_FAILURE as ActionTypes.SIGNUP_FAILURE,
  payload: error,
  meta: {},
  error: true
})

export const loginRequest = (
  provider: AuthProviders,
  email: string,
  password: string
) => ({
  type: ActionTypes.LOGIN_REQUEST as ActionTypes.LOGIN_REQUEST,
  payload: { provider, email, password },
  meta: {},
  error: false
})

export const loginSuccess = () => ({
  type: ActionTypes.LOGIN_SUCCESS as ActionTypes.LOGIN_SUCCESS,
  payload: {},
  meta: {},
  error: false
})

export const loginFailure = (error: Error) => ({
  type: ActionTypes.LOGIN_FAILURE as ActionTypes.LOGIN_FAILURE,
  payload: error,
  meta: {},
  error: true
})

export const logoutRequest = () => ({
  type: ActionTypes.LOGOUT_REQUEST as ActionTypes.LOGOUT_REQUEST,
  payload: {},
  meta: {},
  error: false
})

export const logoutSuccess = () => ({
  type: ActionTypes.LOGOUT_SUCCESS as ActionTypes.LOGOUT_SUCCESS,
  payload: {},
  meta: {},
  error: false
})

export const logoutFailure = (error: Error) => ({
  type: ActionTypes.LOGOUT_FAILURE as ActionTypes.LOGOUT_FAILURE,
  payload: error,
  meta: {},
  error: true
})

export const updateSession = (
  isLoggedIn: boolean,
  id: string | undefined = undefined,
  name: string | undefined = undefined,
  email: string | undefined = undefined,
  photoUrl: string | undefined = undefined,
  isCoach: boolean = false
) => ({
  type: ActionTypes.UPDATE_SESSION as ActionTypes.UPDATE_SESSION,
  payload: { isLoggedIn, id, name, email, photoUrl, isCoach },
  meta: {},
  error: false
})

export type Actions = ReturnType<
  | typeof signupRequest
  | typeof signupSuccess
  | typeof signupFailure
  | typeof loginRequest
  | typeof loginSuccess
  | typeof loginFailure
  | typeof logoutRequest
  | typeof logoutSuccess
  | typeof logoutFailure
  | typeof updateSession
>
