import React from 'react'
import styled from 'styled-components'
import Logo from '../../../assets/images/header_logo.png'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { PropsType } from './Container'

type BudgeProps = {
  budge: number
}

const Presenter: React.FC<PropsType> = ({
  session,
  isOpenDrawer,
  logoutRequest,
  systemShowDrawer,
  systemShowNotification
}) => {
  const location = useLocation()
  const isRouteTop = location.pathname === '/'
  const burgerClass = classNames('navbar-burger', 'is-left', {
    'is-active': isOpenDrawer
  })
  const handleClickBurger = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    systemShowDrawer()
  }
  const handleClickBell = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    systemShowNotification()
  }
  const handleClickLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    logoutRequest()
  }
  return (
    <>
      <nav
        className="navbar is-primary"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <NavbarBrand className="navbar-brand">
            {!isRouteTop && (
              <div className="navbar-item">
                <Link to="/">
                  <LogoImage className="image">
                    <img src={Logo} alt="" />
                  </LogoImage>
                </Link>
              </div>
            )}
            <PositionLeft className="navbar-item is-paddingless">
              <a
                href="/"
                role="button"
                className={burgerClass}
                aria-label="menu"
                aria-expanded="false"
                onClick={handleClickBurger}
              >
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </a>
            </PositionLeft>
            <PositionRight className="navbar-item is-hidden-desktop">
              {session.isLoggedIn ? (
                <div className="buttons">
                  <InBudge
                    href="/"
                    className="button is-primary is-inverted is-outlined is-small"
                    budge={10}
                    onClick={handleClickBell}
                  >
                    <span className="icon">
                      <i className="material-icons-outlined">notifications</i>
                    </span>
                  </InBudge>
                </div>
              ) : (
                <div className="buttons">
                  <Link
                    to="/login"
                    className="button is-primary is-inverted is-outlined is-small"
                  >
                    ログイン
                  </Link>
                  {isRouteTop && (
                    <Link
                      to="/signup"
                      className="button is-primary is-inverted is-outlined is-small"
                    >
                      会員登録
                    </Link>
                  )}
                </div>
              )}
            </PositionRight>
          </NavbarBrand>
          <div className="navbar-menu">
            <div className="navbar-start" />
            <div className="navbar-end">
              {session.isLoggedIn ? (
                <>
                  <div className="navbar-item">
                    <div className="buttons">
                      <InBudge
                        href="/"
                        className="button is-primary is-inverted is-outlined"
                        budge={10}
                        onClick={handleClickBell}
                      >
                        <span className="icon">
                          <i className="material-icons-outlined">
                            notifications
                          </i>
                        </span>
                      </InBudge>
                    </div>
                  </div>
                  <div className="navbar-item has-dropdown is-hoverable">
                    <a href="/" className="navbar-link is-arrowless">
                      <figure className="image is-32x32">
                        <AvatarImage
                          src="https://picsum.photos/100"
                          alt="avatar"
                          className="is-rounded"
                        />
                      </figure>
                    </a>
                    <div className="navbar-dropdown is-boxed is-right">
                      <Link to="/me" className="navbar-item">
                        マイページ
                      </Link>
                      {session.isCoach && (
                        <>
                          <hr className="navbar-divider" />
                          <Link to="/coach" className="navbar-item">
                            運営用TOP
                          </Link>
                          <Link to="/coach/chats" className="navbar-item">
                            質問チャット
                          </Link>
                        </>
                      )}
                      <hr className="navbar-divider" />
                      <Link to="/" className="navbar-item">
                        設定
                      </Link>
                      <Link to="/" className="navbar-item">
                        お問い合わせ
                      </Link>
                      <Link
                        to="/"
                        className="navbar-item"
                        onClick={handleClickLogout}
                      >
                        ログアウト
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                <div className="navbar-item">
                  <div className="buttons">
                    <Link
                      to="/login"
                      className="button is-primary is-inverted is-outlined"
                    >
                      ログイン
                    </Link>
                    <Link
                      to="/signup"
                      className="button is-primary is-inverted is-outlined"
                    >
                      会員登録
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

const NavbarBrand = styled.div`
  @media screen and (max-width: 1023px) {
    justify-content: center;
  }
`

const PositionLeft = styled.div`
  &&& {
    position: absolute;
    left: 0;
  }
`

const PositionRight = styled.div`
  &&& {
    position: absolute;
    right: 0;
  }
`

const LogoImage = styled.figure`
  &&& {
    width: 62px;
  }
`

const InBudge = styled.a`
  position: relative;
  &:after {
    content: '${(props: BudgeProps) => props.budge}';
    display: inline-block;
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: auto;
    left: auto;
    margin: 0;
    padding: 0.1rem 0.25rem;
    text-align: center;
    white-space: nowrap;
    line-height: 1;
    background: #ebff00;
    color: #0f0836;
    min-width: 1rem;
    min-height: 1rem;
    font-size: 0.75rem;
    z-index: 99;
    transform: translate(50%, -50%);
    border-radius: 290486px;
    overflow: visible;
  }
`

const AvatarImage = styled.img`
  &&& {
    max-height: none;
  }
`

export default Presenter
