import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import { AuthProviders } from '../models'

const config = {
  apiKey: 'AIzaSyATSiOdiXmOwigtD-vzyDuaL0YhFpEsBYQ',
  authDomain: 'honq-prod-001.firebaseapp.com',
  databaseURL: 'https://honq-prod-001.firebaseio.com',
  projectId: 'honq-prod-001',
  storageBucket: 'honq-prod-001.appspot.com',
  messagingSenderId: '146472678913',
  appId: '1:146472678913:web:7913746314dd6346ee3200',
  measurementId: 'G-Y2NT6ZPNH9'
}

const firebaseApp = firebase.initializeApp(config)

const facebookProvider = new firebase.auth.FacebookAuthProvider()
facebookProvider.addScope('email')

export const providers = new Map<AuthProviders, firebase.auth.AuthProvider>([
  ['Password', new firebase.auth.EmailAuthProvider()],
  ['Facebook', facebookProvider],
  ['Twitter', new firebase.auth.TwitterAuthProvider()]
])

export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()

export default firebaseApp
