import { combineReducers, Action } from 'redux'
import { History } from 'history'
import {
  connectRouter,
  RouterAction,
  RouterState
} from 'connected-react-router'
import * as SessionReducer from './Session'
import * as CategoryReducer from './Category'
import * as BookReducer from './Book'
import * as ArticleReducer from './Article'
import * as QaReducer from './Qa'
import * as CoachReducer from './Coach'
import * as LoadingReducer from './Loading'
import * as ErrorReducer from './Error'
import * as ApplicationReducer from './Application'

export type ApplicationState = {
  router: RouterState
  session: SessionReducer.State
  category: CategoryReducer.State
  book: BookReducer.State
  article: ArticleReducer.State
  qa: QaReducer.State
  coach: CoachReducer.State
  loading: LoadingReducer.State
  error: ErrorReducer.State
  application: ApplicationReducer.State
}

export type Actions =
  | Action
  | RouterAction
  | SessionReducer.Actions
  | CategoryReducer.Actions
  | BookReducer.Actions
  | ArticleReducer.Actions
  | QaReducer.Actions
  | CoachReducer.Actions
  | ErrorReducer.Actions
  | ApplicationReducer.Actions

export type ModuleActions =
  | SessionReducer.Actions
  | CategoryReducer.Actions
  | BookReducer.Actions
  | ArticleReducer.Actions
  | QaReducer.Actions
  | CoachReducer.Actions
  | ErrorReducer.Actions
  | ApplicationReducer.Actions

const reducers = (history: History) =>
  combineReducers<ApplicationState>({
    router: connectRouter(history),
    session: SessionReducer.reducer,
    category: CategoryReducer.reducer,
    book: BookReducer.reducer,
    article: ArticleReducer.reducer,
    qa: QaReducer.reducer,
    coach: CoachReducer.reducer,
    loading: LoadingReducer.reducer,
    error: ErrorReducer.reducer,
    application: ApplicationReducer.reducer
  })

export default reducers
