import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Footer } from '../../../organisms/Footer'

export const UserChatShowPage: React.FC<{}> = () => {
  return (
    <>
      <section
        className="section has-background-grey-light"
        style={{ paddingTop: '8px', paddingBottom: '8px' }}
      >
        <div className="container">
          <div className="level is-mobile">
            <div className="level-left">
              <div className="level-item">
                <Link
                  to="/mypage"
                  className="has-text-black has-text-weight-bold"
                >
                  ＜マイページに戻る
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <ul className="list is-shadowless">
            {[...Array(10)].map((_, index) => (
              <ListItem key={index}>
                <article className="media">
                  <figure className="media-left">
                    <p className="image is-64x64">
                      <img
                        src="https://bulma.io/images/placeholders/128x128.png"
                        alt="icon"
                      />
                    </p>
                  </figure>
                  <div className="media-content">
                    <div className="content">
                      <p style={{ marginBottom: '4px' }}>
                        <small className="has-text-weight-bold">
                          ユーザー名あああ
                        </small>
                        <small style={{ paddingLeft: '4px' }}>
                          2019/12/01 11:00
                        </small>
                      </p>
                      <p>
                        テキストああああああああああああああああああああああああああああああああああああああああああ
                      </p>
                    </div>
                  </div>
                </article>
              </ListItem>
            ))}
          </ul>
        </div>
      </section>
      <Footer />
      <ChatFloatButton>
        <Link to="/chats/1/create" className="button is-primary is-rounded">
          <span className="icon">
            <i className="material-icons">create</i>
          </span>
          <span>投稿する</span>
        </Link>
      </ChatFloatButton>
    </>
  )
}

const ListItem = styled.li`
  & + & {
    margin-top: 16px;
  }
`

const ChatFloatButton = styled.div`
  position: fixed;
  right: 1rem;
  bottom: 1rem;
`
