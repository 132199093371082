import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import NoImage from '../../../assets/images/no_image_96.png'
import { Link } from 'react-router-dom'
import { PropsType } from './Container'

const Presenter: React.FC<PropsType> = ({ isOpenNotification }) => {
  const drawerClass = classNames({ shown: isOpenNotification })
  return (
    <>
      <DrawerMenu className={drawerClass}>
        <section
          className="section has-background-primary has-text-white"
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
        >
          <div className="container">
            <AlignItemsCenter>
              <span className="icon">
                <i className="material-icons-outlined">notifications</i>
              </span>
              <span className="has-text-weight-bold">新着通知</span>
            </AlignItemsCenter>
          </div>
        </section>
        <section className="section" style={{ padding: '1rem' }}>
          <div className="container">
            <ul className="list is-shadowless is-paddingless">
              {[...Array(5)].map((_, i) => (
                <BorderBottomList key={i}>
                  <Link to="/categories/1/courses/1">
                    <div className="media">
                      <div className="media-left">
                        <figure className="image is-48x48">
                          <img src={NoImage} alt="Placeholder" />
                        </figure>
                      </div>
                      <div className="media-content">
                        <div className="has-text-black">
                          <span>コーチから新着メッセージが届きました</span>
                        </div>
                        <div className="level is-mobile has-text-grey">
                          <div className="level-left">
                            <div className="level-item">
                              <small>たった今</small>
                            </div>
                          </div>
                          <div className="level-right">
                            <div className="level-item">
                              <small>サロン名ああああ…</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </BorderBottomList>
              ))}
            </ul>
          </div>
        </section>
      </DrawerMenu>
    </>
  )
}

const DrawerMenu = styled.aside`
  position: fixed;
  top: 0;
  left: auto;
  right: 0;
  z-index: 1;
  backface-visibility: hidden;
  will-change: transform;
  width: 320px;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: none;
  &.shown {
    display: block;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    transform: translate3d(0, 0, 0);
  }
`

const AlignItemsCenter = styled.div`
  display: flex;
  align-items: center;
`

const BorderBottomList = styled.li`
  padding: 8px;
  border-bottom: 1px solid hsla(19, 5%, 89%, 1);
`

export default Presenter
