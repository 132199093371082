import React from 'react'

type Props = {
  price: number
  title: string
  body: string
  changeInput: (name: string, value: string) => void
  changePrice: (value: number) => void
}

export const CoachQaFormTemplate: React.FC<Props> = ({
  price,
  title,
  body,
  changeInput,
  changePrice
}) => {
  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.persist()
    changeInput(event.target.name, event.target.value)
  }

  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value)
    changePrice(value)
  }

  const handleBlurPrice = () => {
    if (price <= 0) {
      changePrice(0)
    }
  }

  return (
    <>
      <section
        className="section has-background-grey-light"
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
      >
        <div className="container">
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">対象の本</label>
            </div>
            <div className="field-body">
              <div className="field is-narrow">
                <div className="control">
                  <div className="select is-fullwidth">
                    <select>
                      <option>サンプル本A</option>
                      <option>サンプル本B</option>
                      <option>サンプル本C</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">料金</label>
            </div>
            <div className="field-body">
              <div className="field has-addons">
                <div className="control">
                  <input
                    type="number"
                    className="input has-text-right"
                    value={price}
                    onChange={handleChangePrice}
                    onBlur={handleBlurPrice}
                  />
                </div>
                <p className="control">
                  <button className="button is-static">円</button>
                </p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">記事のイメージ</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <button className="button">設定する</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="field">
            <div className="control">
              <input
                name="title"
                className="input"
                type="text"
                placeholder="タイトル"
                value={title}
                onChange={handleChangeInput}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <textarea
                name="body"
                className="textarea has-fixed-size"
                rows={10}
                placeholder="本文"
                value={body}
                onChange={handleChangeInput}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
