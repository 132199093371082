import React from 'react'
import { Link } from 'react-router-dom'
import { PropsType } from './Container'
import { Loading } from '../../molecules/Loading'

const Presenter: React.FC<PropsType> = ({ categories, isLoading, error }) => {
  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return (
      <div className="block">
        <div className="has-text-centered">エラーが発生しました</div>
      </div>
    )
  }

  if (categories.length === 0) {
    return (
      <div className="block">
        <div className="has-text-centered">該当するデータがありません</div>
      </div>
    )
  }

  return (
    <>
      <div className="buttons">
        {categories.map(category => (
          <Link
            to={`/categories/${category.id}`}
            className="button is-rounded is-light has-text-link"
            key={category.id}
          >
            {category.name}
          </Link>
        ))}
      </div>
    </>
  )
}

export default Presenter
