import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { PropsType } from './Container'
import { useInputField } from '../../../../hooks/FormFieldHook'
import { Loading } from '../../../molecules/Loading'
import { ErrorMessage } from '../../../atoms/ErrorMessage'
import { Footer } from '../../../organisms/Footer'

const Presenter: React.FC<PropsType> = ({ isLoading, error, loginRequest }) => {
  const [email, setEmail] = useInputField()
  const [password, setPassword] = useInputField()
  const handleClickFacebook = (e: React.MouseEvent) => {
    e.preventDefault()
    loginRequest('Facebook', '', '')
  }
  const handleClickTwitter = (e: React.MouseEvent) => {
    e.preventDefault()
    loginRequest('Twitter', '', '')
  }
  const handleClickSubmit = (e: React.MouseEvent) => {
    e.preventDefault()
    loginRequest('Password', email, password)
  }
  return (
    <>
      <div className="section">
        <div className="container">
          <div className="column is-6-tablet is-offset-3-tablet">
            <div className="box">
              <h1 className="title is-size-4 has-text-centered">ログイン</h1>
              <ErrorMessage type="LOGIN" error={error} />
              {isLoading ? (
                <Loading />
              ) : (
                <>
                  <div className="buttons">
                    <button
                      className="button is-info is-fullwidth"
                      onClick={handleClickFacebook}
                    >
                      Facebookでログイン
                    </button>
                    <button
                      className="button is-info is-fullwidth"
                      onClick={handleClickTwitter}
                    >
                      Twitterでログイン
                    </button>
                  </div>

                  <SubTitle className="subtitle is-size-6">
                    メールアドレスでログイン
                  </SubTitle>
                  <form>
                    <div className="field">
                      <div className="control">
                        <input
                          className="input is-small"
                          type="text"
                          name="email"
                          placeholder="メールアドレス"
                          value={email}
                          onChange={setEmail}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">
                        <input
                          className="input is-small"
                          type="password"
                          name="password"
                          placeholder="パスワード"
                          value={password}
                          onChange={setPassword}
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="button is-block is-primary is-fullwidth"
                      onClick={handleClickSubmit}
                    >
                      ログイン
                    </button>
                  </form>
                  <Link
                    to="/signup"
                    className="button is-text is-fullwidth has-text-centered"
                  >
                    会員登録はこちら
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

const SubTitle = styled.div`
  &&& {
    margin-bottom: 1rem;
    text-align: center;
  }
`

export default Presenter
