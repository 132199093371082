import React from 'react'

const DEFAULT_MESSAGE = 'エラーが発生しました'
const loginErrorType = new Map<string, string>([
  ['auth/user-not-found', 'メールアドレス、またはパスワードが不正です'],
  ['auth/invalid-email', 'メールアドレス、またはパスワードが不正です'],
  ['auth/wrong-password', 'メールアドレス、またはパスワードが不正です']
])

type Props = {
  type: 'SIGNIN' | 'LOGIN'
  error: Error | undefined
}

export const ErrorMessage: React.FC<Props> = ({ type, error }) => {
  if (error === undefined) {
    return <></>
  }

  let message = undefined
  if (type === 'LOGIN') {
    message = loginErrorType.get(error.message)
  }

  if (message === undefined) {
    message = DEFAULT_MESSAGE
  }

  return (
    <>
      <article className="message is-danger">
        <div className="message-body">{message}</div>
      </article>
    </>
  )
}
