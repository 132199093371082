import React from 'react'
import Component from './Component'
import { mapDispatchToProps, mapStateToProps } from './Container'
import { useParams } from 'react-router-dom'
import { useLoadPageHook } from '../../../../../../hooks/LoadPageHook'

export const ArticleShowPage = (ownProps: any): JSX.Element => {
  const props = { ...mapStateToProps(), ...mapDispatchToProps(), ...ownProps }

  const { article_id } = useParams()
  return useLoadPageHook(
    <Component {...props} />,
    'USER_ARTICLE_SHOW',
    article_id
  )
}
