import { call, put, takeLatest } from 'redux-saga/effects'
import {
  Actions,
  ActionTypes,
  fetchCategoryFailure,
  fetchCategorySuccess,
  fetchPopularCategoryFailure,
  fetchPopularCategorySuccess,
  findCategoryFailure,
  findCategoryRequest,
  findCategorySuccess
} from '../modules/Category'
import {
  fetchCategoryApi,
  fetchPopularCategoryApi,
  findCategoryApi
} from '../apis/CategoryApi'

function* fetchPopularCategoryAction(action: Actions) {
  try {
    const categories = yield call(fetchPopularCategoryApi)
    yield put(fetchPopularCategorySuccess(categories))
  } catch (e) {
    yield put(fetchPopularCategoryFailure(new Error(e.code)))
  }
}

function* fetchCategoryAction(action: Actions) {
  try {
    const categories = yield call(fetchCategoryApi)
    yield put(fetchCategorySuccess(categories))
  } catch (e) {
    yield put(fetchCategoryFailure(new Error(e.code)))
  }
}

function* findCategoryAction(action: ReturnType<typeof findCategoryRequest>) {
  try {
    const category = yield call(findCategoryApi, action.payload.id)
    yield put(findCategorySuccess(category))
  } catch (e) {
    yield put(findCategoryFailure(new Error(e.code)))
  }
}

export function* watchCategoryAction() {
  yield takeLatest(
    ActionTypes.FETCH_POPULAR_CATEGORY_REQUEST,
    fetchPopularCategoryAction
  )
  yield takeLatest(ActionTypes.FETCH_CATEGORY_REQUEST, fetchCategoryAction)
  yield takeLatest(ActionTypes.FIND_CATEGORY_REQUEST, findCategoryAction)
}
