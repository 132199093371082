import React, { useState } from 'react'
import { CoachQaFormTemplate } from '../../../templates/Coach/CoachQaFormTemplate'
import { useHistory } from 'react-router'
import { Footer } from '../../../organisms/Footer'

export const CoachQaCreatePage: React.FC<{}> = () => {
  const history = useHistory()
  const [state, setState] = useState({
    price: 0,
    title: '',
    body: ''
  })

  const handleChangeInput = (name: string, value: string) => {
    setState({ ...state, [name]: value })
  }
  const handleChangePrice = (value: number) => {
    setState({ ...state, price: value })
  }
  const handleCancelButton = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    history.push('/coach')
  }
  const handleDraftButton = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    history.push('/coach')
  }
  const handlePublishButton = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    history.push('/coach')
  }
  return (
    <>
      <div
        className="section has-background-primary"
        style={{ paddingTop: '0', paddingBottom: '8px' }}
      >
        <div className="container">
          <div className="level is-mobile">
            <div className="level-item">
              <button
                className="button"
                style={{ flex: 1 }}
                onClick={handleCancelButton}
              >
                キャンセル
              </button>
            </div>
            <div className="level-item">
              <button
                className="button"
                style={{ flex: 1 }}
                onClick={handleDraftButton}
              >
                下書き
              </button>
            </div>
            <div className="level-item">
              <button
                className="button"
                style={{ flex: 1 }}
                onClick={handlePublishButton}
              >
                公開
              </button>
            </div>
          </div>
        </div>
      </div>
      <CoachQaFormTemplate
        price={state.price}
        title={state.title}
        body={state.body}
        changeInput={handleChangeInput}
        changePrice={handleChangePrice}
      />
      <Footer />
    </>
  )
}
