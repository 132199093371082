import { createGlobalStyle } from 'styled-components'
import './theme.scss'

const GlobalStyle = createGlobalStyle`
  #root {
    overflow-x: hidden;
  }
`

export default GlobalStyle
