import React from 'react'
import Component from './Component'
import { mapDispatchToProps, mapStateToProps } from './Container'
import { useParams } from 'react-router-dom'
import { useLoadPageHook } from '../../../../../hooks/LoadPageHook'

export const CategoryShowPage = (ownProps: any): JSX.Element => {
  const props = { ...mapStateToProps(), ...mapDispatchToProps(), ...ownProps }
  const { category_id } = useParams()

  return useLoadPageHook(
    <Component {...props} />,
    'USER_CATEGORY_SHOW',
    category_id
  )
}
