import React from 'react'
import { Link } from 'react-router-dom'
import BookCoverIcon from '../../../../../../assets/images/book_cover_icon.png'
import {
  ListBookCover,
  ListTitle,
  Tag,
  BorderListItem,
  BlockLink
} from '../../../../../../assets/stylesheets/StyledComponents'
import { Footer } from '../../../../../organisms/Footer'
import { PropsType } from './Container'

const Presenter: React.FC<PropsType> = () => {
  return (
    <>
      <div
        className="section has-background-primary"
        style={{ paddingTop: '0', paddingBottom: '10px' }}
      >
        <div className="container has-text-centered">
          <h1 className="subtitle is-size-5 has-text-white">ゴルフ_GOLF</h1>
        </div>
      </div>
      <div
        className="section has-background-grey-light"
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
      >
        <div className="container has-text-centered">
          <p>記事から本を探す</p>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <h2 className="subtitle">記事一覧</h2>
          <ul className="list is-shadowless is-radiusless">
            {[...Array(5)].map((_, i) => (
              <BorderListItem key={i}>
                <Link to="/articles/1">
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-64x64">
                        <img src={'https://picsum.photos/100'} alt="avatar" />
                      </figure>
                    </div>
                    <div className="media-content has-text-black">
                      <ListTitle>
                        あれ声が遅れているよ？スイングも遅れているよ？ラウンドスピードを守…
                      </ListTitle>
                      <div className="level is-mobile">
                        <div className="level-left">
                          <div className="level-item">
                            <small>TAKAHASHI</small>
                          </div>
                        </div>
                        <div className="level-right">
                          <div className="level-item">
                            <small className="has-text-grey">20XX/MM/DD</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </BorderListItem>
            ))}
          </ul>
          <div className="has-text-centered">
            <button className="button is-rounded is-dark is-outlined">
              <span className="icon">
                <i className="material-icons">keyboard_arrow_down</i>
              </span>
              <span>もっと見る</span>
            </button>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="level is-mobile">
            <div className="level-left">
              <div className="level-item">
                <h2 className="title is-4">本を探す</h2>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <Link
                  to="/books"
                  className="button is-small is-dark is-outlined"
                >
                  すべて見る＞
                </Link>
              </div>
            </div>
          </div>
          <ul className="list is-shadowless is-radiusless">
            {[...Array(5)].map((_, i) => (
              <BorderListItem key={i}>
                <Link to="/books/1">
                  <div className="media">
                    <ListBookCover className="media-left">
                      <figure className="image">
                        <img src={BookCoverIcon} alt="Placeholder" />
                      </figure>
                    </ListBookCover>
                    <div className="media-content">
                      {i % 2 === 0 && (
                        <Tag className="tag is-warning is-rounded">
                          著者本人サポート
                        </Tag>
                      )}
                      <div className="content has-text-black">
                        <ListTitle>
                          初速思考ああああああああああああああああ
                        </ListTitle>
                      </div>
                    </div>
                  </div>
                </Link>
              </BorderListItem>
            ))}
          </ul>
        </div>
      </section>
      <section className="section">
        <div className="container has-text-centered">
          <BlockLink
            to="/categories/1"
            className="button is-rounded is-primary is-outlined has-text-weight-bold"
          >
            カテゴリTOPへ＞
          </BlockLink>
        </div>
      </section>
      <section className="section">
        <div className="container has-text-centered">
          <BlockLink
            to="/categories"
            className="button is-rounded is-primary is-outlined has-text-weight-bold"
          >
            すべてのカテゴリから探す＞
          </BlockLink>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Presenter
