import React, { useState, useCallback } from 'react'

export const useInputField = (
  init: string = ''
): [
  string,
  (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
] => {
  const [value, setValue] = useState(init)
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value)
    },
    [setValue]
  )
  return [value, handleChange]
}
