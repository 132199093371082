import Redux from 'redux'
import { Qa } from '../models'

export type State = {
  qaList: Qa[]
  qa: Qa | undefined
}

const initialState: State = {
  qaList: [],
  qa: undefined
}

export enum ActionTypes {
  FETCH_QA_REQUEST = 'FETCH_QA_REQUEST',
  FETCH_QA_SUCCESS = 'FETCH_QA_SUCCESS',
  FETCH_QA_FAILURE = 'FETCH_QA_FAILURE',
  FIND_QA_REQUEST = 'FIND_QA_REQUEST',
  FIND_QA_SUCCESS = 'FIND_QA_SUCCESS',
  FIND_QA_FAILURE = 'FIND_QA_FAILURE'
}

export const reducer: Redux.Reducer<State, Actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.FETCH_QA_REQUEST: {
      return {
        ...state,
        qaList: []
      }
    }
    case ActionTypes.FETCH_QA_SUCCESS: {
      return {
        ...state,
        qaList: action.payload.qa_list
      }
    }
    case ActionTypes.FIND_QA_SUCCESS: {
      return {
        ...state,
        qa: action.payload.qa
      }
    }
    case ActionTypes.FETCH_QA_FAILURE:
    case ActionTypes.FIND_QA_REQUEST:
    case ActionTypes.FIND_QA_FAILURE: {
      return {
        ...state
      }
    }
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action
      return state
  }
}

export const fetchQaRequest = (categoryId: string) => ({
  type: ActionTypes.FETCH_QA_REQUEST as ActionTypes.FETCH_QA_REQUEST,
  payload: { categoryId },
  meta: {},
  error: false
})

export const fetchQaSuccess = (qa_list: Qa[]) => ({
  type: ActionTypes.FETCH_QA_SUCCESS as ActionTypes.FETCH_QA_SUCCESS,
  payload: { qa_list },
  meta: {},
  error: false
})

export const fetchQaFailure = (error: Error) => ({
  type: ActionTypes.FETCH_QA_FAILURE as ActionTypes.FETCH_QA_FAILURE,
  payload: error,
  meta: {},
  error: true
})

export const findQaRequest = (id: string) => ({
  type: ActionTypes.FIND_QA_REQUEST as ActionTypes.FIND_QA_REQUEST,
  payload: { id },
  meta: {},
  error: false
})

export const findQaSuccess = (qa: Qa) => ({
  type: ActionTypes.FIND_QA_SUCCESS as ActionTypes.FIND_QA_SUCCESS,
  payload: { qa },
  meta: {},
  error: false
})

export const findQaFailure = (error: Error) => ({
  type: ActionTypes.FIND_QA_FAILURE as ActionTypes.FIND_QA_FAILURE,
  payload: error,
  meta: {},
  error: true
})

export type Actions = ReturnType<
  | typeof fetchQaRequest
  | typeof fetchQaSuccess
  | typeof fetchQaFailure
  | typeof findQaRequest
  | typeof findQaSuccess
  | typeof findQaFailure
>
