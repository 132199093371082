import React from 'react'
import { Link } from 'react-router-dom'
import { Props } from './index'
import { Loading } from '../../molecules/Loading'
import {
  BorderListItem,
  ListTitle
} from '../../../assets/stylesheets/StyledComponents'
import styled from 'styled-components'

const Presenter: React.FC<Props> = ({ articles, isLoading, error }) => {
  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return (
      <div className="block">
        <div className="has-text-centered">エラーが発生しました</div>
      </div>
    )
  }

  if (articles.length === 0) {
    return (
      <div className="block">
        <div className="has-text-centered">該当するデータがありません</div>
      </div>
    )
  }

  return (
    <>
      <ul className="list is-shadowless is-radiusless">
        {articles.map(article => (
          <BorderListItem key={article.id}>
            <Link to={`/articles/${article.id}`}>
              <ListTitle>{article.title}</ListTitle>
              <ArchiveInfo>
                <small className="has-text-grey">20XX/MM/DD</small>
                <ArchiveTag className="tag is-light is-rounded">
                  {article.category.name}
                </ArchiveTag>
              </ArchiveInfo>
            </Link>
          </BorderListItem>
        ))}
      </ul>
    </>
  )
}

const ArchiveInfo = styled.p`
  display: flex;
  align-items: center;
`

const ArchiveTag = styled.span`
  &&& {
    margin-left: 8px;
    font-size: 10px;
    height: auto;
  }
`

export default Presenter
