import React from 'react'
import { Link } from 'react-router-dom'
import {
  BorderListItem,
  ListBookCover,
  ListTitle,
  Tag
} from '../../../../../../assets/stylesheets/StyledComponents'
import BookCoverIcon from '../../../../../../assets/images/book_cover_icon.png'
import { Footer } from '../../../../../organisms/Footer'
import styled from 'styled-components'
import { PropsType } from './Container'

const Presenter: React.FC<PropsType> = () => {
  return (
    <>
      <div
        className="section has-background-primary"
        style={{ paddingTop: '0', paddingBottom: '10px' }}
      >
        <div className="container has-text-centered">
          <h2 className="subtitle is-size-5 has-text-white">ゴルフ_GOLF</h2>
        </div>
      </div>
      <div
        className="section has-background-grey-light"
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
      >
        <div className="container has-text-centered">
          <p>おすすめ記事</p>
        </div>
      </div>
      <section className="section">
        <ArticleContainer className="container">
          <h1 className="title is-4">
            記事タイトルあああああああああああああああ
          </h1>
          <div className="block">
            <div className="media">
              <div className="media-left">
                <figure className="image is-32x32">
                  <img
                    src="https://picsum.photos/100"
                    alt="avatar"
                    className="is-rounded"
                  />
                </figure>
              </div>
              <div className="media-content">
                <div className="content">
                  <div className="is-size-7">
                    <span>TAKAHASHI</span>
                  </div>
                  <span className="has-text-grey is-size-7">
                    <time>20XX/MM/DD</time>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <p>
              歴史上、最も大きな成功を収めた製品は何だろうか？
              ──あなたがたった今、それを手に持っている可能性はかなり高い。
            </p>
            <p>
              米アップルの「iPhone」は2007年の発売以降、1兆ドル（約106兆円）相当が販売されている。同社の売上高はこの年以降、10倍近くに跳ね上がった。そして、アップルは初めて時価総額が1兆ドルを超える公開会社となった。
            </p>
            <p>
              そのアップルが、一時は破産しかけていたことを覚えているだろうか？
            </p>
            <p>
              ハイテク株の多くが値上がりしていた1990年代半ばのことだ。同社が当時、主に販売していたのは「Mac」ブランドのコンピューター。だが、その市場シェアは5％に満たない時期もあった。
            </p>
            <p>
              深刻な問題を抱えていた同社は、従業員の3分の1を解雇。あと90日前後で倒産するという状況にまで追い込まれた。時価総額は一時、現在の500分の1に近い20億ドル程度にまで減少した。
            </p>
            <p>
              その後、登場したのが「iPod」だ。2000年代初め、それはまるで魔法のような製品だった。iPodの販売台数は4億を超え、アップルを業績不振のパソコンメーカーから、1000億ドル規模の大企業へと様変わりさせた。
            </p>
            <p>アップルを救った「ビッグアイデア」</p>
            <p>
              iPodとその後に発売したiPhoneは、世界を変える大きなアイデアだった。そして、アップルの過去12年間の成長のほとんどは、iPhoneによってもたらされたものだ。この間の大半において、同社の売上高の約3分の2は、この製品が占めてきた。
            </p>
            <p>
              一方、現在の同社が抱えている問題は、iPhoneの成長が「エネルギー切れ」になり始めたことだ。販売台数は2015年から減少し始めている。だが、アップルはこの厄介な問題を、iPhoneの値上げによってうまく隠してきた。
            </p>
            <p>
              iPhoneの販売台数が落ち込んでいるのは、すでにほとんどの人がスマートフォンを持っているからだ。競合他社に顧客を奪われているわけではない。そこでアップルの投資家たちが考え始めるのは、「次は何か？」ということだ。
            </p>
            <p>
              「Apple
              Watch」の販売により、アップルは現在、世界のどのメーカーより数多くの腕時計を販売している。販売数はタイメックスよりも、ロレックスよりも多い。だが、同社の売上高に占めるウェアラブルの割合が10％に満たない。AppleWatchは、アップルに大きな変化をもたらすほどの大きなアイデアではなかった。もう10年もの間、同社は新たなビッグアイデアを生み出せずにいる。
            </p>
          </div>

          <div style={{ marginBottom: '3.5rem' }}>
            <h2 className="subtitle has-text-weight-bold">
              記事内でおすすめしていた本
            </h2>
            <ul className="list is-shadowless is-radiusless">
              {[...Array(3)].map((_, i) => (
                <BorderListItem key={i}>
                  <Link to="/books/1">
                    <div className="media">
                      <ListBookCover className="media-left">
                        <figure className="image">
                          <img src={BookCoverIcon} alt="Placeholder" />
                        </figure>
                      </ListBookCover>
                      <div className="media-content">
                        {i % 3 === 0 && (
                          <Tag className="tag is-warning is-rounded">
                            著者本人サポート
                          </Tag>
                        )}
                        <div className="content">
                          <ListTitle className="has-text-black">
                            初速思考ああああああああああああああああ
                          </ListTitle>
                          <small className="has-text-grey">
                            解説・QA：999件
                          </small>
                        </div>
                      </div>
                    </div>
                  </Link>
                </BorderListItem>
              ))}
            </ul>
          </div>

          <div className="block">
            <div className="media">
              <div className="media-left">
                <figure className="image is-64x64">
                  <img
                    src="https://picsum.photos/100"
                    alt="avatar"
                    className="is-rounded"
                  />
                </figure>
              </div>
              <div className="media-content">
                <div className="content">
                  <div>
                    <strong>TAKAHASHI</strong>
                  </div>
                  <p>
                    簡易紹介文あああああああああああああああああああああああああああああああああああああああああああああああああああああああああああ
                  </p>
                </div>
              </div>
            </div>
          </div>

          <SimilarContent>
            <h3 className="subtitle">この著者の記事</h3>
            <ul className="list is-shadowless is-radiusless">
              {[...Array(5)].map((_, i) => (
                <BorderListItem key={i}>
                  <Link to="/articles/1">
                    <ListTitle>よくあるスライスの原因</ListTitle>
                    <p>
                      <small className="has-text-grey">20XX/MM/DD</small>
                    </p>
                  </Link>
                </BorderListItem>
              ))}
            </ul>
          </SimilarContent>

          <SimilarContent>
            <h3 className="subtitle">他のカテゴリ名記事</h3>
            <ul className="list is-shadowless is-radiusless">
              {[...Array(5)].map((_, i) => (
                <BorderListItem key={i}>
                  <Link to="/articles/1">
                    <ListTitle>よくあるスライスの原因</ListTitle>
                    <p>
                      <small className="has-text-grey">20XX/MM/DD</small>
                    </p>
                  </Link>
                </BorderListItem>
              ))}
            </ul>
          </SimilarContent>

          <div className="has-text-centered">
            <Link
              to="/articles"
              className="button is-rounded is-dark is-outlined is-medium"
            >
              <span>一覧に戻る</span>
            </Link>
          </div>
        </ArticleContainer>
      </section>
      <Footer />
    </>
  )
}

const ArticleContainer = styled.div`
  &&& {
    max-width: 720px;
  }
`

const SimilarContent = styled.div`
  padding-top: 3.5rem;
  margin-bottom: 1.75rem;
`

export default Presenter
