import { fork } from 'redux-saga/effects'
import { watchSessionAction } from './Session'
import { watchCategoryAction } from './Category'
import { watchBookAction } from './Book'
import { watchArticleAction } from './Article'
import { watchApplicationAction } from './Application'

export default function* rootSaga() {
  yield fork(watchSessionAction)
  yield fork(watchCategoryAction)
  yield fork(watchBookAction)
  yield fork(watchArticleAction)
  yield fork(watchApplicationAction)
}
