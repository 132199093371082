import React from 'react'
import Logo from '../../../assets/images/logo.png'
import { BlockLink } from '../../../assets/stylesheets/StyledComponents'
import { Footer } from '../../organisms/Footer'
import { BookGrid } from '../../organisms/BookGrid'
import { PropsType } from './Container'
import { CategoryTagList } from '../../organisms/CategoryTagList'
import {
  LatestArticleList,
  PopularArticleList
} from '../../organisms/ArticleList'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Presenter: React.FC<PropsType> = ({ isLoading, push }) => {
  return (
    <>
      <section className="hero has-background-primary">
        <div className="hero-body" style={{ paddingBottom: '0.875rem' }}>
          <div className="container has-text-centered">
            <div style={{ width: '138px', margin: 'auto' }}>
              <figure className="image">
                <img src={Logo} alt="honQ" />
              </figure>
            </div>
            <h1 className="subtitle is-6 has-text-white">本のその先を学ぼう</h1>
          </div>
        </div>
      </section>
      <section
        className="section has-background-primary"
        style={{ paddingTop: '0.875rem' }}
      >
        <div className="container">
          <div className="field has-addons has-addons-centered">
            <p className="control is-expanded">
              <input
                className="input"
                type="text"
                placeholder="本のタイトルを入力してください"
              />
            </p>
            <p className="control">
              <button
                className="button is-success"
                onClick={() => push('/search')}
              >
                検索
              </button>
            </p>
          </div>
        </div>
      </section>
      {isLoading ? (
        <section className="section">
          <div className="container has-text-centered">
            <UnderLessLink to="/chats">
              <div className="notification is-warning">
                <strong>！ 新着・未読の質問チャットあり</strong>
              </div>
            </UnderLessLink>
          </div>
        </section>
      ) : (
        <section className="hero has-background-grey-light">
          <div
            className="hero-body"
            style={{ paddingTop: '10px', paddingBottom: '10px' }}
          >
            <div className="container">
              <p>
                honQ（ホンキュー）とは、あなたが学びたい本の記事や解説を見たり、コーチに本に関する質問ができるサービスです。
              </p>
            </div>
          </div>
        </section>
      )}
      <section className="section">
        <div className="container">
          <h2 className="title is-4 is-spaced">学びたい本を探そう</h2>
          <h3 className="subtitle">人気な本</h3>
          <BookGrid />
          <h3 className="subtitle">人気のカテゴリ</h3>
          <CategoryTagList />
          <div className="has-text-centered">
            <BlockLink
              to="/categories"
              className="button is-rounded is-primary is-outlined has-text-weight-bold"
            >
              すべてのカテゴリから探す＞
            </BlockLink>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <h2 className="title is-4 is-spaced">本の解説・QAを見つける</h2>
          <h3 className="subtitle">新着の解説・QA</h3>
          <LatestArticleList />
          <h3 className="subtitle">人気の解説・QA</h3>
          <PopularArticleList />
        </div>
      </section>
      <section className="section has-background-grey-light">
        <div className="container">
          <h3 className="subtitle">人気ワード</h3>
        </div>
      </section>
      <Footer />
    </>
  )
}

const UnderLessLink = styled(Link)`
  &&& {
    text-decoration: none;
  }
`

export default Presenter
