import Redux from 'redux'
import { LoadPageTypes } from '../models'

export type State = {
  isOpenDrawer: boolean
  isOpenNotification: boolean
}

const initialState: State = {
  isOpenDrawer: false,
  isOpenNotification: false
}

export enum ActionTypes {
  SYSTEM_SHOW_DRAWER = 'SYSTEM_SHOW_DRAWER',
  SYSTEM_HIDE_DRAWER = 'SYSTEM_HIDE_DRAWER',
  SYSTEM_SHOW_NOTIFICATION = 'SYSTEM_SHOW_NOTIFICATION',
  SYSTEM_HIDE_NOTIFICATION = 'SYSTEM_HIDE_NOTIFICATION',
  SYSTEM_LOAD_PAGE_REQUEST = 'SYSTEM_LOAD_PAGE_REQUEST',
  SYSTEM_LOAD_PAGE_ACTION = 'SYSTEM_LOAD_PAGE_ACTION',
  SYSTEM_LOAD_PAGE_SUCCESS = 'SYSTEM_LOAD_PAGE_SUCCESS',
  SYSTEM_LOAD_PAGE_FAILURE = 'SYSTEM_LOAD_PAGE_FAILURE'
}

export const reducer: Redux.Reducer<State, Actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.SYSTEM_SHOW_DRAWER: {
      return {
        ...state,
        isOpenDrawer: true
      }
    }
    case ActionTypes.SYSTEM_HIDE_DRAWER: {
      return {
        ...state,
        isOpenDrawer: false
      }
    }
    case ActionTypes.SYSTEM_SHOW_NOTIFICATION: {
      return {
        ...state,
        isOpenNotification: true
      }
    }
    case ActionTypes.SYSTEM_HIDE_NOTIFICATION: {
      return {
        ...state,
        isOpenNotification: false
      }
    }
    case ActionTypes.SYSTEM_LOAD_PAGE_REQUEST:
    case ActionTypes.SYSTEM_LOAD_PAGE_ACTION:
    case ActionTypes.SYSTEM_LOAD_PAGE_SUCCESS:
    case ActionTypes.SYSTEM_LOAD_PAGE_FAILURE: {
      return { ...state }
    }
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action
      return state
  }
}

export const systemShowDrawer = () => ({
  type: ActionTypes.SYSTEM_SHOW_DRAWER as ActionTypes.SYSTEM_SHOW_DRAWER,
  payload: {},
  meta: {},
  error: false
})

export const systemHideDrawer = () => ({
  type: ActionTypes.SYSTEM_HIDE_DRAWER as ActionTypes.SYSTEM_HIDE_DRAWER,
  payload: {},
  meta: {},
  error: false
})

export const systemShowNotification = () => ({
  type: ActionTypes.SYSTEM_SHOW_NOTIFICATION as ActionTypes.SYSTEM_SHOW_NOTIFICATION,
  payload: {},
  meta: {},
  error: false
})

export const systemHideNotification = () => ({
  type: ActionTypes.SYSTEM_HIDE_NOTIFICATION as ActionTypes.SYSTEM_HIDE_NOTIFICATION,
  payload: {},
  meta: {},
  error: false
})

export const systemLoadPageRequest = () => ({
  type: ActionTypes.SYSTEM_LOAD_PAGE_REQUEST as ActionTypes.SYSTEM_LOAD_PAGE_REQUEST,
  payload: {},
  meta: {},
  error: false
})

export const systemLoadPageAction = (type: LoadPageTypes, ...props: any[]) => ({
  type: ActionTypes.SYSTEM_LOAD_PAGE_ACTION as ActionTypes.SYSTEM_LOAD_PAGE_ACTION,
  payload: { type, props },
  meta: {},
  error: false
})

export const systemLoadPageSuccess = () => ({
  type: ActionTypes.SYSTEM_LOAD_PAGE_SUCCESS as ActionTypes.SYSTEM_LOAD_PAGE_SUCCESS,
  payload: {},
  meta: {},
  error: false
})

export const systemLoadPageFailure = (error: Error) => ({
  type: ActionTypes.SYSTEM_LOAD_PAGE_FAILURE as ActionTypes.SYSTEM_LOAD_PAGE_FAILURE,
  payload: error,
  meta: {},
  error: true
})

export type Actions = ReturnType<
  | typeof systemShowDrawer
  | typeof systemHideDrawer
  | typeof systemShowNotification
  | typeof systemHideNotification
  | typeof systemLoadPageRequest
  | typeof systemLoadPageAction
  | typeof systemLoadPageSuccess
  | typeof systemLoadPageFailure
>
