import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../../../modules'

export const mapStateToProps = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const category = useSelector(
    (state: ApplicationState) => state.category.category
  )
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const books = useSelector((state: ApplicationState) => state.book.books)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const articles = useSelector(
    (state: ApplicationState) => state.article.articles
  )
  return { category, books, articles }
}

export const mapDispatchToProps = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => {
    return bindActionCreators({}, dispatch)
  }, [dispatch])
}

export type PropsType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>
