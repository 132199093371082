import React from 'react'
import { Link } from 'react-router-dom'
import {
  BlockLink,
  BorderListItem,
  ListTitle,
  Tag
} from '../../../../../../assets/stylesheets/StyledComponents'
import { Rating } from '../../../../../atoms/Rating'
import { Footer } from '../../../../../organisms/Footer'
import { PropsType } from './Container'
import { NotFoundPage } from '../../../../NotFoundPage'

const Presenter: React.FC<PropsType> = ({ book, coaches }) => {
  if (!book) {
    return <NotFoundPage />
  }
  return (
    <>
      <div
        className="section has-background-primary"
        style={{ paddingTop: '0', paddingBottom: '10px' }}
      >
        <div className="container has-text-centered">
          <h1 className="subtitle is-size-5 has-text-white">
            {book.category.name}
          </h1>
        </div>
      </div>
      <div
        className="section has-background-grey-light"
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
      >
        <div className="container has-text-centered">
          <p>本の詳細</p>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="columns is-mobile">
            <div className="column is-5">
              <p className="image">
                <img src={book.coverUrl} alt="Placeholder" />
              </p>
            </div>
            <div className="column is-7">
              {book.supportCoachId && (
                <Tag className="tag is-warning is-rounded">
                  著者本人サポート
                </Tag>
              )}
              <div className="content has-text-black">
                <ListTitle>{book.title}</ListTitle>
                <small>著者: {book.author}</small>
              </div>
            </div>
          </div>
          <div className="has-text-right">
            <a href={book.url} target="_blank" rel="noreferrer noopener">
              この本を購入する
            </a>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <h2 className="subtitle">この本を質問できるコーチ</h2>
          <ul className="list is-shadowless is-radiusless">
            {coaches.map(coach => (
              <BorderListItem key={coach.id}>
                {book.supportCoachId === coach.id && (
                  <Tag className="tag is-warning is-rounded">
                    著者本人サポート
                  </Tag>
                )}
                <div className="media">
                  <div className="media-left">
                    <figure className="image is-48x48">
                      <img
                        className="is-rounded"
                        src={coach.photoUrl}
                        alt="Placeholder"
                      />
                    </figure>
                  </div>
                  <div className="media-content">
                    <div className="content">
                      <ListTitle>{coach.name}</ListTitle>
                      <div>
                        <span className="has-text-weight-bold">4.2</span>
                        <Rating rate={5} score={4.2} />
                        <small className="has-text-grey">（9999件）</small>
                      </div>
                      <small>
                        コーチからの一言コメントを記載するあああああああああああああああああ
                      </small>
                    </div>
                  </div>
                </div>
                <div className="buttons" style={{ marginTop: '10px' }}>
                  <Link
                    to={`/coaches/${coach.id}`}
                    className="button is-info is-outlined is-rounded has-text-weight-bold"
                  >
                    コーチ紹介
                  </Link>
                  <button
                    className="button is-rounded is-success has-text-weight-bold"
                    style={{ flex: 1 }}
                  >
                    {1 % 2 === 0 ? (
                      <>
                        <span>質問する</span>
                        <span
                          className="tag is-white has-text-success"
                          style={{ fontSize: '7px', marginLeft: '8px' }}
                        >
                          無料
                        </span>
                      </>
                    ) : (
                      <span>1,000,000円で質問する</span>
                    )}
                  </button>
                </div>
              </BorderListItem>
            ))}
          </ul>

          <div className="has-text-centered">
            <button className="button is-rounded is-dark is-outlined">
              <span className="icon">
                <i className="material-icons">keyboard_arrow_down</i>
              </span>
              <span>もっと見る</span>
            </button>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container has-text-centered">
          <BlockLink
            to={`/categories/${book.category.id}/books`}
            className="button is-rounded is-primary is-outlined has-text-weight-bold"
          >
            他の本を探す
          </BlockLink>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Presenter
