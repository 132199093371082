import Redux from 'redux'
import { Categories, Category } from '../models'

export type State = {
  popularCategories: Category[]
  categories: Categories
  category: Category | undefined
}

const initialState: State = {
  popularCategories: [],
  categories: {},
  category: undefined
}

export enum ActionTypes {
  FETCH_POPULAR_CATEGORY_REQUEST = 'FETCH_POPULAR_CATEGORY_REQUEST',
  FETCH_POPULAR_CATEGORY_SUCCESS = 'FETCH_POPULAR_CATEGORY_SUCCESS',
  FETCH_POPULAR_CATEGORY_FAILURE = 'FETCH_POPULAR_CATEGORY_FAILURE',
  FETCH_CATEGORY_REQUEST = 'FETCH_CATEGORY_REQUEST',
  FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS',
  FETCH_CATEGORY_FAILURE = 'FETCH_CATEGORY_FAILURE',
  FIND_CATEGORY_REQUEST = 'FIND_CATEGORY_REQUEST',
  FIND_CATEGORY_SUCCESS = 'FIND_CATEGORY_SUCCESS',
  FIND_CATEGORY_FAILURE = 'FIND_CATEGORY_FAILURE'
}

export const reducer: Redux.Reducer<State, Actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.FETCH_POPULAR_CATEGORY_REQUEST: {
      return {
        ...state,
        popularCategories: []
      }
    }
    case ActionTypes.FETCH_POPULAR_CATEGORY_SUCCESS: {
      return {
        ...state,
        popularCategories: action.payload.categories
      }
    }
    case ActionTypes.FETCH_CATEGORY_REQUEST: {
      return {
        ...state,
        categories: {}
      }
    }
    case ActionTypes.FETCH_CATEGORY_SUCCESS: {
      return {
        ...state,
        categories: action.payload.categories
      }
    }
    case ActionTypes.FIND_CATEGORY_SUCCESS: {
      return {
        ...state,
        category: action.payload.category
      }
    }
    case ActionTypes.FETCH_POPULAR_CATEGORY_FAILURE:
    case ActionTypes.FETCH_CATEGORY_FAILURE:
    case ActionTypes.FIND_CATEGORY_REQUEST:
    case ActionTypes.FIND_CATEGORY_FAILURE: {
      return {
        ...state
      }
    }
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action
      return state
  }
}

export const fetchPopularCategoryRequest = () => ({
  type: ActionTypes.FETCH_POPULAR_CATEGORY_REQUEST as ActionTypes.FETCH_POPULAR_CATEGORY_REQUEST,
  payload: {},
  meta: {},
  error: false
})

export const fetchPopularCategorySuccess = (categories: Category[]) => ({
  type: ActionTypes.FETCH_POPULAR_CATEGORY_SUCCESS as ActionTypes.FETCH_POPULAR_CATEGORY_SUCCESS,
  payload: { categories },
  meta: {},
  error: false
})

export const fetchPopularCategoryFailure = (error: Error) => ({
  type: ActionTypes.FETCH_POPULAR_CATEGORY_FAILURE as ActionTypes.FETCH_POPULAR_CATEGORY_FAILURE,
  payload: error,
  meta: {},
  error: true
})

export const fetchCategoryRequest = () => ({
  type: ActionTypes.FETCH_CATEGORY_REQUEST as ActionTypes.FETCH_CATEGORY_REQUEST,
  payload: {},
  meta: {},
  error: false
})

export const fetchCategorySuccess = (categories: Categories) => ({
  type: ActionTypes.FETCH_CATEGORY_SUCCESS as ActionTypes.FETCH_CATEGORY_SUCCESS,
  payload: { categories },
  meta: {},
  error: false
})

export const fetchCategoryFailure = (error: Error) => ({
  type: ActionTypes.FETCH_CATEGORY_FAILURE as ActionTypes.FETCH_CATEGORY_FAILURE,
  payload: error,
  meta: {},
  error: true
})

export const findCategoryRequest = (id: string) => ({
  type: ActionTypes.FIND_CATEGORY_REQUEST as ActionTypes.FIND_CATEGORY_REQUEST,
  payload: { id },
  meta: {},
  error: false
})

export const findCategorySuccess = (category: Category) => ({
  type: ActionTypes.FIND_CATEGORY_SUCCESS as ActionTypes.FIND_CATEGORY_SUCCESS,
  payload: { category },
  meta: {},
  error: false
})

export const findCategoryFailure = (error: Error) => ({
  type: ActionTypes.FIND_CATEGORY_FAILURE as ActionTypes.FIND_CATEGORY_FAILURE,
  payload: error,
  meta: {},
  error: true
})

export type Actions = ReturnType<
  | typeof fetchPopularCategoryRequest
  | typeof fetchPopularCategorySuccess
  | typeof fetchPopularCategoryFailure
  | typeof fetchCategoryRequest
  | typeof fetchCategorySuccess
  | typeof fetchCategoryFailure
  | typeof findCategoryRequest
  | typeof findCategorySuccess
  | typeof findCategoryFailure
>
