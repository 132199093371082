import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../modules'
import { createLoadingSelector } from '../modules/Loading'
import { ActionTypes, systemLoadPageAction } from '../modules/Application'
import { createErrorSelector } from '../modules/Error'
import React, { useEffect, useMemo } from 'react'
import { NotFoundPage } from '../components/pages/NotFoundPage'
import { Loading } from '../components/molecules/Loading'
import { bindActionCreators } from 'redux'
import { LoadPageTypes } from '../models'

export const useLoadPageHook = (
  element: JSX.Element,
  action: LoadPageTypes,
  ...parameter: any[]
): JSX.Element => {
  const isPageLoading = useSelector((state: ApplicationState) =>
    createLoadingSelector(state.loading, ActionTypes.SYSTEM_LOAD_PAGE_REQUEST)
  )

  const error = useSelector((state: ApplicationState) =>
    createErrorSelector(state.error, ActionTypes.SYSTEM_LOAD_PAGE_REQUEST)
  )

  const dispatch = useDispatch()
  const loadPageAction = useMemo(() => {
    return bindActionCreators(systemLoadPageAction, dispatch)
  }, [dispatch])

  useEffect(() => {
    loadPageAction(action, ...parameter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    return <NotFoundPage />
  }
  if (isPageLoading) {
    return <Loading />
  }
  return element
}
