import React from 'react'
import { Link } from 'react-router-dom'
import {
  BlockLink,
  BorderListItem,
  ListTitle,
  Tag
} from '../../../../../../assets/stylesheets/StyledComponents'
import styled from 'styled-components'
import { Footer } from '../../../../../organisms/Footer'
import { PropsType } from './Container'
import { NotFoundPage } from '../../../../NotFoundPage'

const Presenter: React.FC<PropsType> = ({ book, qaList }) => {
  if (!book) {
    return <NotFoundPage />
  }
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="columns is-mobile">
            <div className="column is-5">
              <p className="image">
                <img src={book.coverUrl} alt="Placeholder" />
              </p>
            </div>
            <div className="column is-7">
              {book.supportCoachId && (
                <Tag className="tag is-warning is-rounded">
                  著者本人サポート
                </Tag>
              )}
              <div className="content has-text-black">
                <ListTitle>{book.title}</ListTitle>
                <small>著者: {book.author}</small>
              </div>
            </div>
          </div>
          <div className="has-text-right">
            <a href={book.url} target="_blank" rel="noreferrer noopener">
              この本を購入する
            </a>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container has-text-centered">
          <StyledBlockLink
            to={`/books/${book.id}/coaches`}
            className="button is-rounded is-primary is-outlined has-text-weight-bold"
          >
            この本を質問できるコーチを探す
          </StyledBlockLink>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <h2 className="subtitle">記事を探す</h2>
          <ul className="list is-shadowless is-radiusless">
            {qaList.map(qa => (
              <BorderListItem key={qa.id}>
                <Link to={`/qa/${qa.id}`}>
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-48x48">
                        <img src={qa.user.photoUrl} alt="Placeholder" />
                      </figure>
                    </div>
                    <div className="media-content">
                      <div className="content has-text-black">
                        <ListTitle>{qa.title}</ListTitle>
                        <div className="level is-mobile">
                          <div className="level-left">
                            <div className="level-item">
                              <small>{qa.user.name}</small>
                            </div>
                          </div>
                          <div className="level-right">
                            <div className="level-item">
                              <small>¥{qa.price}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </BorderListItem>
            ))}
          </ul>

          <div className="has-text-centered">
            <button className="button is-rounded is-dark is-outlined">
              <span className="icon">
                <i className="material-icons">keyboard_arrow_down</i>
              </span>
              <span>もっと見る</span>
            </button>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container has-text-centered">
          <BlockLink
            to={`/categories/${book.category.id}`}
            className="button is-rounded is-primary is-outlined has-text-weight-bold"
          >
            他の本を探す
          </BlockLink>
        </div>
      </section>
      <Footer />
    </>
  )
}

const StyledBlockLink = styled(BlockLink)`
  &&& {
    font-size: 1rem;
  }
`

export default Presenter
