import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { PropsType } from './Container'
import { Loading } from '../../molecules/Loading'
import { Tag } from '../../../assets/stylesheets/StyledComponents'

const Presenter: React.FC<PropsType> = ({ books, isLoading, error }) => {
  if (isLoading) {
    return <Loading />
  }

  if (error) {
    return (
      <div className="block">
        <div className="has-text-centered">エラーが発生しました</div>
      </div>
    )
  }

  if (books.length === 0) {
    return (
      <div className="block">
        <div className="has-text-centered">該当するデータがありません</div>
      </div>
    )
  }

  return (
    <>
      <BookCoverColumns className="columns is-multiline is-mobile">
        {books.map(book => (
          <div className="column is-6-mobile is-3-tablet" key={book.id}>
            <Link to={`/books/${book.id}`}>
              {book.supportCoachId ? (
                <Tag className="tag is-warning is-rounded">
                  著者本人サポート
                </Tag>
              ) : (
                <Tag className="tag is-white is-rounded" />
              )}
              <GridBookCover>
                <figure className="image">
                  <img src={book.coverUrl} alt="cover" />
                </figure>
              </GridBookCover>
              <BookTitle>{book.title}</BookTitle>
            </Link>
          </div>
        ))}
      </BookCoverColumns>
    </>
  )
}

const BookCoverColumns = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`

const GridBookCover = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 4px;
`

const BookTitle = styled.div`
  font-size: 0.8rem;
`

export default Presenter
