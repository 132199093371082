import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { history } from '../store'
import { ApplicationLayout } from '../layouts/ApplicationLayout'
import { NotFoundPage } from '../components/pages/NotFoundPage'
import { UserRoutePaths, CoachRoutePath } from './RoutePath'

// const LoggedInRoute = connectConditionalRoute<ApplicationState>(state => ({
//   redirectTo: '/login',
//   routeCondition: false
// }))
//
// const NotLoggedInRoute = connectConditionalRoute<ApplicationState>(state => ({
//   redirectTo: '/',
//   routeCondition: true
// }))

const Routes: React.FC<{}> = () => (
  <BrowserRouter>
    <ConnectedRouter history={history}>
      <ApplicationLayout>
        <Switch>
          {UserRoutePaths.map((route, index) => (
            <Route
              key={route.path}
              path={route.path}
              exact={true}
              component={route.component}
            />
          ))}
          {CoachRoutePath.map((route, index) => (
            <Route
              key={route.path}
              path={route.path}
              exact={true}
              component={route.component}
            />
          ))}
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </ApplicationLayout>
    </ConnectedRouter>
  </BrowserRouter>
)

export default Routes
