import { TopPage } from '../components/pages/TopPage'
import { LoginPage } from '../components/pages/Auth/LoginPage'
import { SignupPage } from '../components/pages/Auth/SignupPage'
import { SearchIndexPage } from '../components/pages/Users/Search/SearchIndexPage'
import { CategoryIndexPage } from '../components/pages/Users/Categories/CategoryIndexPage'
import { CategoryShowPage } from '../components/pages/Users/Categories/CategoryShowPage'
import { ArticleIndexPage } from '../components/pages/Users/Categories/Articles/ArticleIndexPage'
import { ArticleShowPage } from '../components/pages/Users/Categories/Articles/ArticleShowPage'
import { BookIndexPage } from '../components/pages/Users/Categories/Books/BookIndexPage'
import { BookShowPage } from '../components/pages/Users/Categories/Books/BookShowPage'
import { BookCoachPage } from '../components/pages/Users/Categories/Books/BookCoachPage'
import { BookQaPage } from '../components/pages/Users/Categories/Books/BookQaPage'
import { CoachShowPage } from '../components/pages/Users/Coaches/CoachShowPage'
// import { CourseIndexPage } from '../components/pages/Users/Categories/Courses/CourseIndexPage'
// import { CourseShowPage } from '../components/pages/Users/Categories/Courses/CourseShowPage'
import { MePage } from '../components/pages/MePage'
import { UserChatShowPage } from '../components/pages/Users/Chats/UserChatShowPage'
import { UserChatCreatePage } from '../components/pages/Users/Chats/UserChatCreatePage'
// import { CourseTopPage } from '../components/pages/Courses/CourseTopPage'
// import { CourseArticleIndexPage } from '../components/pages/Courses/Articles/CourseArticleIndexPage'
// import { CourseArticleShowPage } from '../components/pages/Courses/Articles/CourseArticleShowPage'
import { CoachTopPage } from '../components/pages/Coaches/CoachTopPage'
// import { NewBasicInformationPage } from '../components/pages/Coaches/Courses/New/NewBasicInformationPage'
// import { NewSummaryPage } from '../components/pages/Coaches/Courses/New/NewSummaryPage'
// import { NewSupportBookPage } from '../components/pages/Coaches/Courses/New/NewSupportBookPage'
// import { EditBasicInformationPage } from '../components/pages/Coaches/Courses/Edit/EditBasicInformationPage'
// import { NewConfirmPage } from '../components/pages/Coaches/Courses/New/NewConfirmPage'
// import { EditSummaryPage } from '../components/pages/Coaches/Courses/Edit/EditSummaryPage'
import { RequestSupportBookPage } from '../components/pages/Coaches/Courses/RequestSupportBookPage'
import { CoachChatIndexPage } from '../components/pages/Coaches/Chats/CoachChatIndexPage'
import { CoachChatShowPage } from '../components/pages/Coaches/Chats/CoachChatShowPage'
import { CoachChatCreatePage } from '../components/pages/Coaches/Chats/CoachChatCreatePage'
import { CoachArticleIndexPage } from '../components/pages/Coaches/Articles/CoachArticleIndexPage'
import { CoachArticleCreatePage } from '../components/pages/Coaches/Articles/CoachArticleCreatePage'
import { CoachArticleEditPage } from '../components/pages/Coaches/Articles/CoachArticleEditPage'
import { CoachQaIndexPage } from '../components/pages/Coaches/qa/CoachQaIndexPage'
import { CoachQaCreatePage } from '../components/pages/Coaches/qa/CoachQaCreatePage'
import { CoachQaEditPage } from '../components/pages/Coaches/qa/CoachQaEditPage'
import { CoachPlanCreatePage } from '../components/pages/Coaches/Plans/CoachPlanCreatePage'
import { CoachPlanEditPage } from '../components/pages/Coaches/Plans/CoachPlanEditPage'

export const UserRoutePaths = [
  { path: '/', component: TopPage },
  { path: '/login', component: LoginPage },
  { path: '/signup', component: SignupPage },
  { path: '/search', component: SearchIndexPage },
  { path: '/categories', component: CategoryIndexPage },
  { path: '/categories/:category_id', component: CategoryShowPage },
  { path: '/categories/:category_id/articles', component: ArticleIndexPage },
  {
    path: '/articles/:article_id',
    component: ArticleShowPage
  },
  { path: '/categories/:category_id/books', component: BookIndexPage },
  { path: '/books/:book_id', component: BookShowPage },
  {
    path: '/books/:book_id/coaches',
    component: BookCoachPage
  },
  {
    path: '/qa/:faq_id',
    component: BookQaPage
  },
  { path: '/coaches/:coach_id', component: CoachShowPage },
  // { path: '/categories/:category_id/courses', component: CourseIndexPage },
  // { path: '/categories/:category_id/courses/:course_id', component: CourseShowPage },
  { path: '/me', component: MePage },
  { path: '/chats/:chat_id', component: UserChatShowPage },
  { path: '/chats/:chat_id/create', component: UserChatCreatePage }
  // { path: '//courses/:course_id', component: CourseTopPage },
  // { path: '/courses/:course_id/articles', component: CourseArticleIndexPage },
  // { path: '/courses/:course_id/articles/:article_id', component: CourseArticleShowPage },
]

export const CoachRoutePath = [
  { path: '/coach', component: CoachTopPage },
  // { path: '/coach/new', component: NewBasicInformationPage },
  // { path: '/coach/new/summary', component: NewSummaryPage },
  // { path: '/coach/new/book', component: NewSupportBookPage },
  // { path: '/coach/new/confirm', component: NewConfirmPage },
  // { path: '/coach/edit/info', component: EditBasicInformationPage },
  // { path: '/coach/edit/summary', component: EditSummaryPage },
  { path: '/coach/request_book', component: RequestSupportBookPage },
  { path: '/coach/chats', component: CoachChatIndexPage },
  { path: '/coach/chats/:id', component: CoachChatShowPage },
  { path: '/coach/chats/:id/create', component: CoachChatCreatePage },
  { path: '/coach/articles', component: CoachArticleIndexPage },
  { path: '/coach/articles/create', component: CoachArticleCreatePage },
  { path: '/coach/articles/:article_id', component: CoachArticleEditPage },
  { path: '/coach/qa', component: CoachQaIndexPage },
  { path: '/coach/qa/create', component: CoachQaCreatePage },
  { path: '/coach/qa/:qa_id', component: CoachQaEditPage },
  { path: '/coach/plans/create', component: CoachPlanCreatePage },
  { path: '/coach/plans/:plan_id', component: CoachPlanEditPage }
]
