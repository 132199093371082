import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { ApplicationState } from '../../../../modules'
import { useDispatch, useSelector } from 'react-redux'
import { ActionTypes, signupRequest } from '../../../../modules/Session'
import { createErrorSelector } from '../../../../modules/Error'

export const mapStateToProps = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const error = useSelector((state: ApplicationState) =>
    createErrorSelector(state.error, ActionTypes.SIGNUP_REQUEST)
  )
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isLoading = useSelector(
    (state: ApplicationState) => state.session.isLoading
  )
  return { error, isLoading }
}

export const mapDispatchToProps = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => {
    return bindActionCreators({ signupRequest }, dispatch)
  }, [dispatch])
}

export type PropsType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>
