import firebase from 'firebase'
import { Coach } from '../models'
import { NotFountError } from '../models/ErrorType'

export async function fetchCoachApi(
  bookId: string,
  limit: number | undefined = undefined
) {
  const refs = firebase
    .firestore()
    .collection('books')
    .doc(bookId)
    .collection('coaches')
  if (limit) {
    refs.limit(limit)
  }
  const snapShot = await refs.get()
  const coaches: Coach[] = snapShot.docs.map((document: any) => {
    return {
      id: document.id,
      supportBooks: [],
      ...document.data()
    }
  })
  return coaches
}

export async function findCoachApi(id: string) {
  const document = await firebase
    .firestore()
    .collection('coaches')
    .doc(id)
    .get()
  if (document.exists) {
    return { id: document.id, ...document.data() }
  } else {
    throw new NotFountError()
  }
}
