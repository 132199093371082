import { call, put, takeLatest } from 'redux-saga/effects'
import { fetchPopularBookApi } from '../apis/BookApi'
import {
  Actions,
  ActionTypes,
  fetchPopularBookFailure,
  fetchPopularBookSuccess
} from '../modules/Book'

function* fetchPopularBookAction(action: Actions) {
  try {
    const categories = yield call(fetchPopularBookApi)
    yield put(fetchPopularBookSuccess(categories))
  } catch (e) {
    yield put(fetchPopularBookFailure(new Error(e.code)))
  }
}

export function* watchBookAction() {
  yield takeLatest(
    ActionTypes.FETCH_POPULAR_BOOK_REQUEST,
    fetchPopularBookAction
  )
}
