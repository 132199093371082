import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../../modules'
import { createLoadingSelector } from '../../../modules/Loading'
import { createErrorSelector } from '../../../modules/Error'
import {
  ActionTypes,
  fetchPopularCategoryRequest
} from '../../../modules/Category'

export const mapStateToProps = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const categories = useSelector(
    (state: ApplicationState) => state.category.popularCategories
  )
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isLoading = useSelector((state: ApplicationState) =>
    createLoadingSelector(
      state.loading,
      ActionTypes.FETCH_POPULAR_CATEGORY_REQUEST
    )
  )
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const error = useSelector((state: ApplicationState) =>
    createErrorSelector(state.error, ActionTypes.FETCH_POPULAR_CATEGORY_REQUEST)
  )
  return { categories, isLoading, error }
}

export const mapDispatchToProps = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => {
    return bindActionCreators({ fetchPopularCategoryRequest }, dispatch)
  }, [dispatch])
}

export type PropsType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>
