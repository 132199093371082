import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  BorderListItem,
  ListBookCover,
  ListTitle,
  Tag
} from '../../../../../assets/stylesheets/StyledComponents'
import { Footer } from '../../../../organisms/Footer'
import { PropsType } from './Container'
import { NotFoundPage } from '../../../NotFoundPage'
import classNames from 'classnames'

const Presenter: React.FC<PropsType> = ({ category, books, articles }) => {
  const [viewType, setViewType] = useState('grid')
  const handleClickListType = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setViewType('list')
  }
  const handleClickGridType = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setViewType('grid')
  }
  const listTypeClassName = classNames('button', 'is-small', {
    'is-light': viewType === 'list',
    'is-selected': viewType === 'list'
  })
  const gridTypeClassName = classNames('button', 'is-small', {
    'is-light': viewType === 'grid',
    'is-selected': viewType === 'grid'
  })

  if (!category) {
    return <NotFoundPage />
  }
  return (
    <>
      <div
        className="section has-background-primary"
        style={{ paddingTop: '0', paddingBottom: '10px' }}
      >
        <div className="container has-text-centered">
          <h1 className="subtitle is-size-5 has-text-white">{category.name}</h1>
        </div>
      </div>
      <div
        className="section"
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
      >
        <div className="container has-text-centered">
          <div className="level is-mobile">
            <div className="level-left">
              <div className="level-item">
                <small>{books.length}件</small>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <div className="buttons has-addons">
                  <button
                    className={listTypeClassName}
                    onClick={handleClickListType}
                  >
                    <span className="icon">
                      <i className="material-icons">view_list</i>
                    </span>
                  </button>
                  <button
                    className={gridTypeClassName}
                    onClick={handleClickGridType}
                  >
                    <span className="icon">
                      <i className="material-icons">view_module</i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {viewType === 'grid' && (
        <section className="section" style={{ paddingTop: '0' }}>
          <div className="container">
            <BookCoverColumns className="columns is-multiline is-mobile">
              {books.map(book => (
                <div className="column is-6-mobile is-3-tablet" key={book.id}>
                  <Link to={`/books/${book.id}`}>
                    {book.supportCoachId ? (
                      <Tag className="tag is-warning is-rounded">
                        著者本人サポート
                      </Tag>
                    ) : (
                      <Tag className="tag is-white is-rounded" />
                    )}
                    <GridBookCover>
                      <figure className="image">
                        <img src={book.coverUrl} alt="cover" />
                      </figure>
                    </GridBookCover>
                    <BookTitle>{book.title}</BookTitle>
                  </Link>
                </div>
              ))}
            </BookCoverColumns>
            <div className="has-text-centered">
              <button className="button is-rounded is-dark is-outlined">
                <span className="icon">
                  <i className="material-icons">keyboard_arrow_down</i>
                </span>
                <span>もっと見る</span>
              </button>
            </div>
          </div>
        </section>
      )}
      {viewType === 'list' && (
        <section className="section" style={{ paddingTop: '0' }}>
          <div className="container">
            <ul className="list is-shadowless is-radiusless">
              {books.map(book => (
                <BorderListItem key={book.id}>
                  <Link to={`/books/${book.id}`}>
                    <div className="media">
                      <ListBookCover className="media-left">
                        <figure className="image">
                          <img src={book.coverUrl} alt="Placeholder" />
                        </figure>
                      </ListBookCover>
                      <div className="media-content">
                        {book.supportCoachId && (
                          <Tag className="tag is-warning is-rounded">
                            著者本人サポート
                          </Tag>
                        )}
                        <div className="content has-text-black">
                          <ListTitle>{book.title}</ListTitle>
                        </div>
                      </div>
                    </div>
                  </Link>
                </BorderListItem>
              ))}
            </ul>
            <div className="has-text-centered">
              <button className="button is-rounded is-dark is-outlined">
                <span className="icon">
                  <i className="material-icons">keyboard_arrow_down</i>
                </span>
                <span>もっと見る</span>
              </button>
            </div>
          </div>
        </section>
      )}
      <section className="section">
        <div className="container">
          <h2 className="title is-4">本の紹介記事から探す</h2>
          {articles.length > 0 ? (
            <ul className="list is-shadowless is-radiusless">
              {articles.map(article => (
                <BorderListItem key={article.id}>
                  <Link to={`/articles/${article.id}`}>
                    <ListTitle>{article.title}</ListTitle>
                    <small className="has-text-grey">20XX/MM/DD</small>
                  </Link>
                </BorderListItem>
              ))}
            </ul>
          ) : (
            <div className="has-text-centered has-text-grey">
              該当するデータがありません
            </div>
          )}
        </div>
      </section>
      <Footer />
    </>
  )
}

const BookCoverColumns = styled.div`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`

const GridBookCover = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 4px;
`

const BookTitle = styled.div`
  font-size: 0.8rem;
`

export default Presenter
