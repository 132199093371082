import Redux from 'redux'
import { Book } from '../models'

export type State = {
  popularBooks: Book[]
  books: Book[]
  book: Book | undefined
}

const initialState: State = {
  popularBooks: [],
  books: [],
  book: undefined
}

export enum ActionTypes {
  FETCH_POPULAR_BOOK_REQUEST = 'FETCH_POPULAR_BOOK_REQUEST',
  FETCH_POPULAR_BOOK_SUCCESS = 'FETCH_POPULAR_BOOK_SUCCESS',
  FETCH_POPULAR_BOOK_FAILURE = 'FETCH_POPULAR_BOOK_FAILURE',
  FETCH_BOOK_REQUEST = 'FETCH_BOOK_REQUEST',
  FETCH_BOOK_SUCCESS = 'FETCH_BOOK_SUCCESS',
  FETCH_BOOK_FAILURE = 'FETCH_BOOK_FAILURE',
  FIND_BOOK_REQUEST = 'FIND_BOOK_REQUEST',
  FIND_BOOK_SUCCESS = 'FIND_BOOK_SUCCESS',
  FIND_BOOK_FAILURE = 'FIND_BOOK_FAILURE'
}

export const reducer: Redux.Reducer<State, Actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionTypes.FETCH_POPULAR_BOOK_REQUEST: {
      return {
        ...state,
        popularBooks: []
      }
    }
    case ActionTypes.FETCH_POPULAR_BOOK_SUCCESS: {
      return {
        ...state,
        popularBooks: action.payload.books
      }
    }
    case ActionTypes.FETCH_BOOK_REQUEST: {
      return {
        ...state,
        books: []
      }
    }
    case ActionTypes.FETCH_BOOK_SUCCESS: {
      return {
        ...state,
        books: action.payload.books
      }
    }
    case ActionTypes.FIND_BOOK_SUCCESS: {
      return {
        ...state,
        book: action.payload.book
      }
    }
    case ActionTypes.FETCH_POPULAR_BOOK_FAILURE:
    case ActionTypes.FETCH_BOOK_FAILURE:
    case ActionTypes.FIND_BOOK_REQUEST:
    case ActionTypes.FIND_BOOK_FAILURE: {
      return {
        ...state
      }
    }
    default:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action
      return state
  }
}

export const fetchPopularBookRequest = () => ({
  type: ActionTypes.FETCH_POPULAR_BOOK_REQUEST as ActionTypes.FETCH_POPULAR_BOOK_REQUEST,
  payload: {},
  meta: {},
  error: false
})

export const fetchPopularBookSuccess = (books: Book[]) => ({
  type: ActionTypes.FETCH_POPULAR_BOOK_SUCCESS as ActionTypes.FETCH_POPULAR_BOOK_SUCCESS,
  payload: { books },
  meta: {},
  error: false
})

export const fetchPopularBookFailure = (error: Error) => ({
  type: ActionTypes.FETCH_POPULAR_BOOK_FAILURE as ActionTypes.FETCH_POPULAR_BOOK_FAILURE,
  payload: error,
  meta: {},
  error: true
})

export const fetchBookRequest = (categoryId: string) => ({
  type: ActionTypes.FETCH_BOOK_REQUEST as ActionTypes.FETCH_BOOK_REQUEST,
  payload: { categoryId },
  meta: {},
  error: false
})

export const fetchBookSuccess = (books: Book[]) => ({
  type: ActionTypes.FETCH_BOOK_SUCCESS as ActionTypes.FETCH_BOOK_SUCCESS,
  payload: { books },
  meta: {},
  error: false
})

export const fetchBookFailure = (error: Error) => ({
  type: ActionTypes.FETCH_BOOK_FAILURE as ActionTypes.FETCH_BOOK_FAILURE,
  payload: error,
  meta: {},
  error: true
})

export const findBookRequest = (id: string) => ({
  type: ActionTypes.FIND_BOOK_REQUEST as ActionTypes.FIND_BOOK_REQUEST,
  payload: { id },
  meta: {},
  error: false
})

export const findBookSuccess = (book: Book) => ({
  type: ActionTypes.FIND_BOOK_SUCCESS as ActionTypes.FIND_BOOK_SUCCESS,
  payload: { book },
  meta: {},
  error: false
})

export const findBookFailure = (error: Error) => ({
  type: ActionTypes.FIND_BOOK_FAILURE as ActionTypes.FIND_BOOK_FAILURE,
  payload: error,
  meta: {},
  error: true
})

export type Actions = ReturnType<
  | typeof fetchPopularBookRequest
  | typeof fetchPopularBookSuccess
  | typeof fetchPopularBookFailure
  | typeof fetchBookRequest
  | typeof fetchBookSuccess
  | typeof fetchBookFailure
  | typeof findBookRequest
  | typeof findBookSuccess
  | typeof findBookFailure
>
