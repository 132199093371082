import React from 'react'
import ThreeDots from '../../../assets/images/three-dots.svg'

type Props = {}

export const Loading: React.FC<Props> = () => {
  return (
    <section className="hero">
      <div className="hero-body">
        <div className="container has-text-centered">
          <img src={ThreeDots} alt="loading" />
        </div>
      </div>
    </section>
  )
}
