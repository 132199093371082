import React from 'react'
import styled from 'styled-components'

type Props = {
  rate: number
  score: number
}

type StyledProps = {
  rate: number
}

export const Rating: React.FC<Props> = ({ rate, score }) => {
  let star = [...Array(rate)].map(() => '★')
  const percent = (score / rate) * 100
  return (
    <>
      <Wrap>
        <div className="has-text-grey-light">{star}</div>
        <FillArea rate={percent} className="has-text-warning">
          {star}
        </FillArea>
      </Wrap>
    </>
  )
}

const Wrap = styled.div`
  display: inline-block;
  position: relative;
`

const FillArea = styled.div`
  position: absolute;
  top: 0;
  overflow: hidden;
  white-space: nowrap;
  width: 50%;
  ${(props: StyledProps) => `width: ${props.rate}%`}
`
