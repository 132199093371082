import React from 'react'
import styled from 'styled-components'

export const Hr: React.FC<{}> = () => {
  return (
    <>
      <StyledHr />
    </>
  )
}

const StyledHr = styled.hr`
  margin: 0 1rem;
`
