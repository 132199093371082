import React from 'react'
import CourseCoverImage from '../../../../assets/images/course_cover_image.png'
import { Link, useHistory } from 'react-router-dom'
import BookCoverIcon from '../../../../assets/images/book_cover_icon.png'
import styled from 'styled-components'
import {
  BorderListItem,
  ListBookCover,
  ListTitle,
  Tag
} from '../../../../assets/stylesheets/StyledComponents'
import { Rating } from '../../../atoms/Rating'
import { Footer } from '../../../organisms/Footer'

export const CoachShowPage: React.FC<{}> = () => {
  const history = useHistory()
  return (
    <>
      <section className="hero">
        <div className="hero-body is-paddingless">
          <div className="container">
            <figure className="image">
              <img src={CourseCoverImage} alt="cover" />
            </figure>
            <GoBackButton
              className="button has-addons is-rounded"
              onClick={() => history.goBack()}
            >
              <i className="material-icons">keyboard_backspace</i>
            </GoBackButton>
          </div>
        </div>
      </section>
      <section
        className="section"
        style={{ paddingTop: '0.75rem', paddingBottom: 0 }}
      >
        <div className="container">
          <div className="level is-mobile">
            <div className="level-left">
              <div className="level-item">
                <AbsoluteAvatar>
                  <figure className="image is-64x64">
                    <img
                      className="is-rounded"
                      src={'https://picsum.photos/100'}
                      alt="Placeholder"
                    />
                  </figure>
                </AbsoluteAvatar>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <button className="button is-info is-outlined is-small">
                  フォローする
                </button>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="has-text-weight-bold">TAKA高HASHI</div>
            <div>
              <small>肩書き名称あああああああああああ</small>
            </div>
            <div>
              <span className="has-text-weight-bold">4.2</span>
              <Rating rate={5} score={4.2} />
              <small className="has-text-grey">（9999件）</small>

              <span className="has-text-weight-bold">312</span>
              <small>フォロワー</small>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="content">
            <p>
              簡易紹介文あああああああああああああああああああああああああああああああああああああああああああああああああああああああああああ
            </p>
          </div>

          <h2 className="subtitle has-text-weight-bold">経歴</h2>
          <div className="content">
            <p>
              あああああああああああああああああああああああああああああああああ
            </p>
            <p>
              あああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああ
            </p>
          </div>

          <h2 className="subtitle has-text-weight-bold">
            <span>サポート対象の本</span>
            <small className="has-text-grey-light">（55）</small>
          </h2>
          <ul className="list is-shadowless is-radiusless">
            {[...Array(5)].map((_, i) => (
              <BorderListItem key={i}>
                <Link to="/books/1">
                  <div className="media">
                    <ListBookCover className="media-left">
                      <figure className="image">
                        <img src={BookCoverIcon} alt="Placeholder" />
                      </figure>
                    </ListBookCover>
                    <div className="media-content">
                      {i % 3 === 0 && (
                        <Tag className="tag is-warning is-rounded">
                          著者本人サポート
                        </Tag>
                      )}
                      <div className="content">
                        <ListTitle className="has-text-black">
                          初速思考ああああああああああああああああ
                        </ListTitle>
                        <small className="has-text-grey">解説・QA：999件</small>
                      </div>
                    </div>
                  </div>
                </Link>
              </BorderListItem>
            ))}
          </ul>

          <h2 className="subtitle has-text-weight-bold">投稿記事</h2>
          <ul className="list is-shadowless is-radiusless">
            {[...Array(5)].map((_, i) => (
              <BorderListItem key={i}>
                {i % 3 === 0 ? (
                  <Link to="/articles/1" className="has-text-black">
                    <ListTitle>
                      あれ声が遅れているよ？スイングも遅れているよ？ラウンドスピードを守…
                    </ListTitle>
                    <div className="level is-mobile">
                      <div className="level-left">
                        <div className="level-item">
                          <small>本おすすめ記事</small>
                        </div>
                      </div>
                      <div className="level-right">
                        <div className="level-item">
                          <small className="has-text-grey">20XX/MM/DD</small>
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <Link to="qa/1" className="has-text-black">
                    <ListTitle>
                      あれ声が遅れているよ？スイングも遅れているよ？ラウンドスピードを守…
                    </ListTitle>
                    <div className="level is-mobile">
                      <div className="level-left">
                        <div className="level-item">
                          <small>本の解説QA記事</small>
                        </div>
                      </div>
                      <div className="level-right">
                        <div className="level-item">
                          <small className="has-text-grey">20XX/MM/DD</small>
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
              </BorderListItem>
            ))}
          </ul>

          <h2 className="subtitle has-text-weight-bold">
            フリーテキスト見出し
          </h2>
          <div className="content">
            <p>
              あああああああああああああああああああああああああああああああああ
            </p>
            <p>
              あああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああ
            </p>
          </div>

          <h2 className="subtitle has-text-weight-bold">メッセージ返信目安</h2>
          <div className="block">24時間以内</div>

          <h2 className="subtitle has-text-weight-bold">SNS</h2>
          <div className="block">〇〇〇</div>

          <h2 className="subtitle has-text-weight-bold">運営サイトなど</h2>
          <div className="block">〇〇〇</div>
        </div>
      </section>
      <section className="section">
        <div className="container has-text-centered">
          <SubmitButton className="button is-rounded is-success has-text-weight-bold">
            質問する
          </SubmitButton>
        </div>
      </section>
      <Footer />
    </>
  )
}

const GoBackButton = styled.button`
  &&& {
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 0.5rem;
    opacity: 0.7;
    background: #000000;
    border: none;
    color: #ffffff;
  }
`

const AbsoluteAvatar = styled.div`
  position: absolute;
  top: -29%;
  left: 0;
  & img {
    border: 2px solid #ffffff;
  }
`

const SubmitButton = styled.button`
  &&& {
    min-height: 60px;
    border-width: 2px;
    font-size: 1.1rem;
    width: 80%;
  }
`
